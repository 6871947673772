import { BrowserRouter } from "react-router-dom";
import Routing from "./routers/Routing";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@emotion/react";
import theme from "./config/theme";

// global css
import "react-toastify/dist/ReactToastify.css";
import "font-awesome/css/font-awesome.min.css";
import "./style/sass/main.scss";

const defaultHistory = createBrowserHistory();

function App() {
  
  return (
      <BrowserRouter history={defaultHistory}>
        <ThemeProvider theme={theme}>
          <Routing />
          <ToastContainer className="toaster-container"/>
        </ThemeProvider>
      </BrowserRouter>
  );
}

export default App;
