import { snop, user } from "../config";
import { getLocalStorage } from "../helper/localStorage";
import { CONFIGURATION_KEY } from "./ConfigurationKey";


const LOCAL_STORAGE = {
  TOKEN: getLocalStorage(CONFIGURATION_KEY.TOKEN),
  BU_IDS: user?.business_unit_id,
  TENANT_IDS: user?.tenant_id,
  SNOP_IDS: snop?.snop_id,
};

export default LOCAL_STORAGE;
