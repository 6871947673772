import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "Roboto", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#E94F1C",
    },
    secondary: {
      main: "#1D1D1B",
      light: "#efefef",
    },
    input: {
      color: "rgba(0, 0, 0, 0.87)",
      border: "#ACB2BA",
      sideBorder: "#E6E6E6"
    },
    myCommon: {
      lily: "#acb2ba",
      review: "#50ce6c",
      reviewDisabled: "#d5d9dd",
      disable: "#edf0f3"
    }
  },
});

export default theme;
