import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  filterObj,
  groupLevelFilter,
  LIGHT_TYPE,
  whenSorting,
} from "../../../utils/Constant";
import { toastMsg } from "../../../helper/toast-helper";
import STATUS_CODE from "../../../utils/StatusCode";
import ERROR_MSG from "../../../utils/ErrorMsg";
import { getDemandSummary } from "../../../services/marketing";

const initialState = {
  headerData: [],
  bodyData: [],
  isProgress: false,
  progressMsg: "",
  pageIndex: 1,
  pageSize: 10,
  totalPage: 0,
  searchKey: "",
  sortKey: "",
  sortValue: false,
  filterObj,
  groupLevelFilter,
  whenSorting,
  isGroupFilterApply: false,
  selectedData: [],
  summaryData: {},
};

export const fetchSummaryData = createAsyncThunk(
  "Marketing/fetchSummaryData",
  async ({ filterObj }) => {
    try {
      const { status, data } = await getDemandSummary({
        filterObj,
      });
      if (status === STATUS_CODE.OK) {
        let responseData = data?.responseData?.data;
        return responseData || {};
      } else {
        toastMsg(LIGHT_TYPE.ERROR, ERROR_MSG.SWR);
      }
      return {};
    } catch (e) {
      toastMsg(LIGHT_TYPE.ERROR, e.message || ERROR_MSG.SWR);
    }
    return {};
  }
);

export const MarketingSlice = createSlice({
  name: "Marketing",
  initialState: initialState,
  reducers: {
    getMarketingSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
    progressMarketing: (state, action) => {
      state.isProgress = action.payload.isProgress;
      state.progressMsg = action.payload.progressMsg;
    },
    resetMarketing: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSummaryData.fulfilled, (state, action) => {
        state.summaryData = action.payload;
      })
      .addCase(fetchSummaryData.rejected, (state) => {
        state.summaryData = {};
      });
  },
});

export const { getMarketingSlice, resetMarketing, progressMarketing } =
  MarketingSlice.actions;

export default MarketingSlice.reducer;

export const selectMarketing = (state) => state.MarketingReducer;
