const END_POINT = {
  DEMAND_SUMMARY: "/marketingforecastsummary",
  GET_MARKETING: "/fetchmarketingforecast",
  SAVE_MARKETING: "/marketing_forecast",
  GET_CSV_MARKETING: "/marketingforecastexcel",
  UPLOAD_CSV_MARKETING: "/marketing_forecast/uploadcsv",
  FILTER: "/filter",
  REJECT: "/marketing_forecast/reject",
  REVIEW: "/marketing_forecast/review",
  MARKETING_SUMMARY: "/marketing_summary",
};

export default END_POINT;