import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import propTypes from "prop-types";

const StyledTableRow = styled(TableRow)(() => ({
  // "&:nth-of-type(2n+3)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // "&:nth-of-type(2n+)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

function TabRow({ children, ...props }) {
  return <StyledTableRow {...props}>{children}</StyledTableRow>;
}

export default TabRow;

TabRow.propTypes = {
  children: propTypes.node,
};
