import propTypes from "prop-types";

const RenderSortIcons = ({ column, sortKey, sortDir }) => {
  return (
    <>
      {sortKey === column ? (
        !sortDir ? (
          <i className="fa fa-arrow-up" aria-hidden="true"></i>
        ) : (
          <i className="fa fa-arrow-down" aria-hidden="true"></i>
        )
      ) : (
        <>
          {" "}
          <i className="fa fa-sort" aria-hidden="true"></i>
        </>
      )}
    </>
  );
};

export default RenderSortIcons;

RenderSortIcons.propTypes = {
  column: propTypes.string,
  sortKey: propTypes.string,
  sortDir: propTypes.bool,
};
