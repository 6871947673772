import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import propTypes from "prop-types";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 9,
  },
}));

export default function CusTooltip({ title, children, ...otherProps }) {
  return (
    <BootstrapTooltip title={title} {...otherProps}>
      {children}
    </BootstrapTooltip>
  );
}

CusTooltip.propTypes = {
  title: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
};
