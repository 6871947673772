import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/button";
import CsvDownload from "../../components/csv-download";
import CsvUpload from "../../components/csv-upload";
import CusDialog from "../../components/cus-dialog";
import CustomDialog from "../../components/dialog";
import InputGroupSelectFilter from "../../components/inputGroupSelectFilter";
import NoRecord from "../../components/no-record";
import { TitleTypo } from "../../components/reuse-comp-fn";
import ReactSelect from "../../components/select-field/react-select";
import Tables from "../../components/tables";
import CusPagination from "../../components/tables/cus-pagination";
import {
  isRejectButtonDisabled,
  optionReviewFun,
} from "../../helper/input-helper";
import { saveMarketingPayload } from "../../helper/payload-helper";
import {
  findAndUpdate,
  reviewAndRejectFindAndUpdate,
} from "../../helper/table-helper";
import { toastMsg } from "../../helper/toast-helper";
import { groupFilterLabels } from "../../helper/user-helper";
import {
  csvUploadMarketingURL,
  getCsvDownloadMarketingURL,
  getMarketingSummary,
  rejectMarketing,
  reviewMarketing,
  saveMarketing,
} from "../../services/marketing";
import { selectGlobalConfig } from "../../store/slices/globalConfig";
import { loadingProgress } from "../../store/slices/loading";
import {
  getMarketingSlice,
  selectMarketing,
} from "../../store/slices/marketing";
import { GET_MARKETING } from "../../store/types";
import COMMON_MSG from "../../utils/CommonMsg";
import { LIGHT_TYPE, optionReview, optionRow } from "../../utils/Constant";
import ERROR_MSG from "../../utils/ErrorMsg";
import STATUS_CODE from "../../utils/StatusCode";
import SearchFrom from "../../components/search-form";

function GridTable() {
  const [hashMapSaveStatus, setHashMapSaveStatus] = useState({});
  const [hashMapSaveValue, setHashMapSaveValue] = useState({});
  const [forecastUpload, setForecastUpload] = useState(false);
  const [reviewAllPopup, setReviewAllPopup] = useState(false);
  const { filterData } = useSelector(selectGlobalConfig);
  const dispatch = useDispatch();
  const marketingTimeoutRef = useRef(null);
  const {
    pageIndex,
    searchKey,
    pageSize,
    sortKey,
    sortValue,
    filterObj,
    totalPage,
    headerData,
    bodyData,
    groupLevelFilter,
    whenSorting,
    isGroupFilterApply,
    selectedData,
    summaryData
  } = useSelector(selectMarketing);
  const isEnabled = bodyData.length > 0;

  useEffect(() => {
    dispatch({
      type: GET_MARKETING,
      payload: {
        pageIndex,
        pageSize,
        sortKey,
        sortValue,
        filterObj,
        groupLevelFilter,
        searchKey,
      },
    });
  }, [
    dispatch,
    pageIndex,
    pageSize,
    sortKey,
    sortValue,
    filterObj,
    groupLevelFilter,
    searchKey,
  ]);

  useEffect(() => {
    const isHashMapSaveValueEmpty = Object.keys(hashMapSaveValue).length === 0;
    if (isHashMapSaveValueEmpty) return;
    let newBodyData = bodyData;
    for (const key in hashMapSaveValue) {
      const data = hashMapSaveValue[key];
      const uniqueKey = `${data?.si}-${data?.ni}-${data?.ci}`;
      const newFd = data?.fd?.[0] || [];
      const newFhi = data?.fhi;
      const newSt = data?.st;
      const newAtl = data?.atl;
      newBodyData = findAndUpdate({
        data: [...newBodyData],
        item: newFd,
        uniqueKey,
        fhi: newFhi,
        st: newSt,
        atl: newAtl,
      });
      delete hashMapSaveValue[key];
    }
    if (marketingTimeoutRef.current) clearTimeout(marketingTimeoutRef.current);
    marketingTimeoutRef.current = setTimeout(() => {
      getMarketingSummary(filterObj).then(({data, status}) => {
        if(status !== STATUS_CODE.OK) return;
        const res = data?.responseData?.data?.m || [];
        const newSummaryData = { ...summaryData, m: res };
        dispatch(getMarketingSlice({ summaryData: newSummaryData }))
      });
    }, 3000);
    dispatch(getMarketingSlice({ bodyData: [...newBodyData] }));

    return () => {
      clearTimeout(marketingTimeoutRef.current);
    };
  }, [hashMapSaveValue]);

  const deleteTimeOutFun = (statusKey) => {
    let deleteTimeOut = setTimeout(() => {
      setHashMapSaveStatus((prev) => {
        let newObj = { ...prev };
        delete newObj[statusKey];
        return newObj;
      });
      clearTimeout(deleteTimeOut);
    }, 5000);
  };

  const handleSaveBlur = async ({ value, item, ni, si, ci, fhi }) => {
    if (!value || +value === item.vo) return;
    const uniqueKey = `${si}-${ni}-${ci}`;
    let statusKey = `${uniqueKey}-${item.ft}-${item.p}`;
    const payload = saveMarketingPayload({ value, item, ni, si, ci, fhi });

    try {
      setHashMapSaveStatus((prev) => ({
        ...prev,
        [statusKey]: LIGHT_TYPE.LOADING,
      }));
      const { status, data } = await saveMarketing({
        data: payload,
        isAvl: item.isAvl,
      });
      if (status === STATUS_CODE.OK) {
        let newItem = data?.responseData?.data?.[0];
        setHashMapSaveValue((prev) => ({ ...prev, [statusKey]: newItem }));
        setHashMapSaveStatus((prev) => ({
          ...prev,
          [statusKey]: LIGHT_TYPE.SUCCESS,
        }));
        deleteTimeOutFun(statusKey);
      } else {
        throw new Error(ERROR_MSG.SWR);
      }
    } catch (e) {
      setHashMapSaveStatus((prev) => ({
        ...prev,
        [statusKey]: LIGHT_TYPE.ERROR,
      }));
      deleteTimeOutFun(statusKey);
    }
  };

  const handleSort = (key) => {
    dispatch(
      getMarketingSlice({ sortKey: whenSorting[key], sortValue: !sortValue })
    );
  };

  const handleSearchChange = (value) => {
    dispatch(getMarketingSlice({ pageIndex: 1, searchKey: value }));
  };

  const handleSelectChange = (e) => {
    dispatch(getMarketingSlice({ pageIndex: 1, pageSize: e.value }));
  };

  const handleChangePage = (page) => {
    dispatch(getMarketingSlice({ pageIndex: page }));
  };

  const handleGroupLevelFilter = (groupLevelFilter) => {
    dispatch(getMarketingSlice({ groupLevelFilter, pageIndex: 1 }));
  };

  const handleUploadClick = () => {
    setForecastUpload(true);
  };

  const handleUploadClose = () => {
    setForecastUpload(false);
  };

  const handleCsvUploadResponse = (bodyData) => {
    if (bodyData) dispatch(getMarketingSlice({ bodyData }));
    handleUploadClose();
  };

  const handleOnCheckboxChange = (e, fhi) => {
    let checked = e.target.checked;
    let newSelectedData = [...selectedData];
    if (checked) {
      newSelectedData.push(fhi);
    } else {
      newSelectedData = newSelectedData.filter((item) => item !== fhi);
    }
    dispatch(getMarketingSlice({ selectedData: newSelectedData }));
  };

  const handleOnReject = async () => {
    try {
      dispatch(loadingProgress({ isProgress: true }));
      let { status, data } = await rejectMarketing({ fhi: selectedData });
      if (status === STATUS_CODE.OK) {
        let res = data?.responseData?.data || [];
        const newData = reviewAndRejectFindAndUpdate({ data: res, bodyData });
        dispatch(getMarketingSlice({ selectedData: [], bodyData: newData }));
        dispatch(loadingProgress({ isProgress: false }));
        toastMsg(LIGHT_TYPE.SUCCESS, COMMON_MSG.FRS);
      } else {
        throw new Error(ERROR_MSG.SWR);
      }
    } catch (e) {
      toastMsg(LIGHT_TYPE.ERROR, e?.message || ERROR_MSG.SWR);
      dispatch(loadingProgress({ isProgress: false }));
    }
  };

  const handleOnReview = async (e) => {
    try {
      if (e.value === "reviewed") {
        dispatch(loadingProgress({ isProgress: true }));
        const { status, data } = await reviewMarketing({ fhi: selectedData });
        if (STATUS_CODE.OK === status) {
          let res = data?.responseData?.data || [];
          const newData = reviewAndRejectFindAndUpdate({ data: res, bodyData });
          dispatch(
            getMarketingSlice({
              selectedData: [],
              bodyData: newData,
            })
          );
          dispatch(loadingProgress({ isProgress: false }));
          toastMsg(LIGHT_TYPE.SUCCESS, COMMON_MSG.FAS);
        } else {
          throw new Error(ERROR_MSG.SWR);
        }
      } else {
        setReviewAllPopup(true);
      }
    } catch (e) {
      toastMsg(LIGHT_TYPE.ERROR, e?.message || ERROR_MSG.SWR);
      dispatch(loadingProgress({ isProgress: false }));
    }
  };

  const handleOkReviewAll = async () => {
    setReviewAllPopup(false);
    try {
      dispatch(loadingProgress({ isProgress: true }));
      const { status } = await reviewMarketing({ fhi: [] });
      if (STATUS_CODE.OK === status) {
        dispatch({
          type: GET_MARKETING,
          payload: {
            pageIndex: 1,
            pageSize: 10,
            filterObj: {},
            groupLevelFilter: [],
            searchKey: "",
            notificationMsg: COMMON_MSG.FAS,
          },
        });
      } else {
        throw new Error(ERROR_MSG.SWR);
      }
    } catch (e) {
      toastMsg(LIGHT_TYPE.ERROR, e?.message || ERROR_MSG.SWR);
      dispatch(loadingProgress({ isProgress: false }));
    }
  };

  const handleReviewAll = () => {
    setReviewAllPopup(false);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        mt={1.5}
        mb={1}
        flexWrap="wrap"
        gap={1}
      >
        <Stack flexDirection="row" gap={1}>
          <SearchFrom handleOnSearch={handleSearchChange} />
          <ReactSelect
            placeholder="Show 10 Rows"
            options={optionRow}
            value={{
              label: `Show ${pageSize} Rows`,
              value: pageSize,
            }}
            handleSelectChange={handleSelectChange}
          />
          {/* grouping fun disabled for now */}
          {false && (
            <InputGroupSelectFilter
              value={groupLevelFilter}
              handleApplyFilterCallback={handleGroupLevelFilter}
              data={groupFilterLabels({ filterData })}
            />
          )}
        </Stack>
        <Stack flexDirection="row" gap={1}>
          <Button
            buttonType="primary"
            label="Reject"
            disabled={isRejectButtonDisabled({ selectedData, bodyData })}
            onClick={handleOnReject}
          />
          <ReactSelect
            placeholder="Review"
            options={optionReviewFun({
              optionReview,
              selectedLn: selectedData.length,
            })}
            value={null}
            onChange={handleOnReview}
          />
          <Button onClick={handleUploadClick} buttonType={"upload"} />
          <CsvDownload
            type="get"
            url={getCsvDownloadMarketingURL}
            fileName="Product Planning"
          />
        </Stack>
      </Stack>

      {isEnabled ? (
        <>
          <Tables
            headerData={headerData}
            bodyData={bodyData}
            handleBlur={handleSaveBlur}
            handleSort={handleSort}
            sortKey={sortKey}
            sortDir={sortValue}
            hashMapSaveStatus={hashMapSaveStatus}
            whenSorting={whenSorting}
            isGroupFilterApply={isGroupFilterApply}
            handleOnCheckboxChange={handleOnCheckboxChange}
            selectedData={selectedData}
          />
          <CusPagination
            page={pageIndex}
            rowPerPage={pageSize}
            total={totalPage}
            handleChangePage={handleChangePage}
          />
        </>
      ) : (
        <NoRecord>{ERROR_MSG.NRF}</NoRecord>
      )}

      <CustomDialog
        open={forecastUpload}
        handleClose={handleUploadClose}
        handleOpen={handleUploadClick}
        className={"upload-dialog-container"}
      >
        <CsvUpload
          handleResponse={handleCsvUploadResponse}
          url={csvUploadMarketingURL({
            pageIndex,
            pageSize,
            searchKey: searchKey || "",
            sortKey,
            sortValue,
            groupLevelFilter,
          })}
          type="put"
          filterObj={filterObj}
        />
      </CustomDialog>

      <CusDialog
        open={reviewAllPopup}
        onClose={handleReviewAll}
        isDialogActionShow
        title="Please Confirm"
        isCloseIcon={false}
        handleOkClick={handleOkReviewAll}
      >
        <TitleTypo>{COMMON_MSG.RUS}</TitleTypo>
      </CusDialog>
    </>
  );
}

export default GridTable;
