import { TextField, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";

const CustomizeTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: "3.52rem",
  },
  "& .MuiFormLabel-root": {
    color: `${theme.palette.input.border} !important`,
    fontSize: "1rem",
    marginTop: "0.4rem",
  },
  "& label.Mui-focused": {
    color: theme.palette.input.color,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.input.border,
    borderBottomWidth: "0.1rem",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.input.border,
      borderWidth: "0.1rem",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.input.border,
      borderWidth: "0.1rem",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.input.border,
      borderWidth: "0.1rem",
    },
  },
}));

const InputField = ({ adornmentIcon, endAdornmentClear, ...otherProps }) => {
  return (
    <CustomizeTextField
      {...otherProps}
      InputProps={{
        ...(adornmentIcon
          ? adornmentIcon
          : {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }),
        ...(endAdornmentClear && { endAdornment: endAdornmentClear }),
      }}
    />
  );
};

export default InputField;

InputField.propTypes = {
  adornmentIcon: PropTypes.object,
  endAdornmentClear: PropTypes.object,
};
