import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import {
    locationConfiguration,
    productConfiguration
} from "../../../config/";

const FilterDropdown = (props) => {
    const hierarchyLevelData =
        props.responseData[props.dropDownType + props.hierarchyLevel];

    const [options, SetOptions] = useState([]);
    const [dropDownType, setDropDownType] = useState();
    const [parentType, SetParentType] = useState(props.parentType);
    const [selected, SetSelected] = useState([]);
    const [selectedData, setSelectedData] = useState(props.selectedData);
    const [initialLoadChild, SetInitialLoadChild] = useState({
        initialLoad: false,
    });
    const overrideStrings = {
        allItemsAreSelected: "All",
        selectAll: "Select All",
    };
    const [clearAll, SetClearAll] = useState(props.clearAll);

    const onChangeClick = (values) => {
        if (values?.length) {
            setDropDownType(props.dropDownType);
            SetSelected(values);
            let dataObj = values?.map((data) => {
                return data.value;
            });
            SetParentType(dataObj);
        } else {
            SetSelected([]);
            SetParentType([]);
        }
    };

    const getAllOptions = (parentType) => {
        let allOptions = [];
        let DUPLICATE_ARRAY = [];
        if (hierarchyLevelData && hierarchyLevelData?.length > 0) {
            hierarchyLevelData.map((level) => {
                if (
                    parentType.includes(level.parent) &&
                    DUPLICATE_ARRAY.indexOf(level.value) == -1
                ) {
                    allOptions.push(level);
                    DUPLICATE_ARRAY.push(level.value);
                }
            });
        }

        return allOptions;
    };

    const getInitialLoadOptions = () => {
        let initialLoadOptions = [];
        let aboveLevelParentType = [];
        if (
            props.selectedData[props.dropDownName] &&
            props.selectedData[props.dropDownName][
            props.dropDownType + (Number(props.hierarchyLevel) + 1)
            ]
        ) {
            aboveLevelParentType = props.selectedData[props.dropDownName][
                props.dropDownType + (Number(props.hierarchyLevel) + 1)
            ].map((aboveLevelValue) => {
                return aboveLevelValue.value;
            });
            initialLoadOptions = getAllOptions(aboveLevelParentType);
        }
        return initialLoadOptions;
    };

    const afterEffectSteps = (filterData, initialLoad) => {
        let allOptions = getAllOptions(props.parentType);
        // Set the options in the dropdown
        SetOptions(allOptions);

        if (initialLoad) {
            // Check the existing values of all options
            let initialLoadOptions = getInitialLoadOptions();
            SetOptions(
                initialLoadOptions.length > 0 ? initialLoadOptions : allOptions
            );
            let selectedOptions =
                filterData.length > 0
                    ? filterData
                    : initialLoadOptions.length > 0
                        ? initialLoadOptions
                        : allOptions;
            // Getting the parent type of the values for child dropdown
            let childParentTypes = selectedOptions.map((data) => {
                return data.value;
            });
            // Set the selected data in the dropdown
            SetSelected(selectedOptions);
            SetParentType(childParentTypes);
            SetInitialLoadChild({ initialLoad: true });
        } else {
            let receivedFilterData =
                filterData.length > 0 ? filterData : allOptions ?? [];
            // Set the selected data in the dropdown
            SetSelected(receivedFilterData);
            // Getting the parent type of the values for child dropdown
            let childParentTypes = receivedFilterData.map((data) => {
                return data.value;
            });
            setDropDownType(props.dropDownType);
            SetParentType(childParentTypes);
        }
    };

    useEffect(() => {
        if (props.applyAction.isApplied) {
            let tempSelectedData = props.selectedData;
            if (!tempSelectedData[props.dropDownName]) {
                tempSelectedData[props.dropDownName] = {};
            }
            if (options?.length != selected?.length) {
                tempSelectedData[props.dropDownName][
                    props.dropDownType + props.hierarchyLevel
                ] = selected;
            } else {
                if (tempSelectedData[props.dropDownName]) {
                    delete tempSelectedData[props.dropDownName][
                        props.dropDownType + props.hierarchyLevel
                    ];
                }
            }
            setSelectedData(tempSelectedData);
        }
    }, [props.applyAction]);

    useEffect(() => {
        if (props.initialLoadChild && props.initialLoadChild.initialLoad) {
            let filterData = [];
            if (
                props.selectedData[props.dropDownName] &&
                props.selectedData[props.dropDownName][
                props.dropDownType + props.hierarchyLevel
                ]
            ) {
                filterData =
                    props.selectedData[props.dropDownName][
                    props.dropDownType + props.hierarchyLevel
                    ];
            }
            afterEffectSteps(filterData, true);
        }
    }, [props.initialLoadChild]);

    useEffect(() => {
        if (
            props.drawer &&
            props.drawer.right &&
            props.hierarchyLevel ==
            Object.keys(props.responseData)[0]?.replace(/[^\d]/g, "")
        ) {
            let filterData = [];
            if (
                props.selectedData[props.dropDownName] &&
                props.selectedData[props.dropDownName][
                props.dropDownType + props.hierarchyLevel
                ]
            ) {
                filterData =
                    props.selectedData[props.dropDownName][
                    props.dropDownType + props.hierarchyLevel
                    ];
            }
            afterEffectSteps(filterData, true);
        } else {
            SetInitialLoadChild({ initialLoad: false });
            SetClearAll({ isClearAll: false });
        }
    }, [props.drawer]);

    useEffect(() => {
        let filterData = [];
        if (
            props.selectedData[props.dropDownName] &&
            props.selectedData[props.dropDownName][
            props.dropDownType + props.hierarchyLevel
            ]
        ) {
            filterData =
                props.selectedData[props.dropDownName][
                props.dropDownType + props.hierarchyLevel
                ];
        }
        afterEffectSteps(filterData, false);
    }, [props.parentType, props.selectedData]);

    useEffect(() => {
        setSelectedData(props.selectedData);
    }, [props.selectedData]);

    useEffect(() => {
        // if clear all clicked the data has to be cleared
        if (props.clearAll.isClearAll) {
            // Set the options in the dropdown
            SetOptions(hierarchyLevelData);
            // Set the selected data in the dropdown
            SetSelected(hierarchyLevelData);
            // set clearall true
            SetClearAll({ isClearAll: true });
        }
    }, [props.clearAll]);

    return (
        <>
            <div className="dropdown-container">
                <label>
                    {`${dropDownType}`.toUpperCase() === "PRODUCTHIERARCHYLEVEL" &&
                        productConfiguration
                        ? `${props.hierarchyLevel}` == "0"
                            ? productConfiguration["base"]
                            : productConfiguration[`${dropDownType}${props.hierarchyLevel}`]
                        : `${dropDownType}`.toUpperCase() === "LOCATIONHIERARCHYLEVEL" &&
                            locationConfiguration
                            ? `${props.hierarchyLevel}` == "0"
                                ? locationConfiguration["base"]
                                : locationConfiguration[`${dropDownType}${props.hierarchyLevel}`]
                            : "Channel"
                    }
                </label>
                <MultiSelect
                    options={options || []}
                    value={selected ?? []}
                    onChange={(values) => {
                        onChangeClick(values);
                    }}
                    overrideStrings={overrideStrings}
                    ClearSelectedIcon
                />
            </div>
            {/* <br /> */}
            {/* <div> */}
            {props.noOfDropDown != 0 && (
                <FilterDropdown
                    noOfDropDown={props.noOfDropDown - 1}
                    hierarchyLevel={props.hierarchyLevel - 1}
                    responseData={props.responseData}
                    selectedData={selectedData}
                    dropDownType={dropDownType}
                    dropDownName={props.dropDownName}
                    clearAll={clearAll}
                    applyAction={props.applyAction}
                    initialLoadChild={initialLoadChild}
                    parentType={parentType}
                />
            )}
            {/* </div> */}
        </>
    );
};

export default FilterDropdown;
