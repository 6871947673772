import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import propsTypes from "prop-types";
import "./style.scss";

export const CloseIcon = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 0C7.1635 0 0 7.1635 0 16C0 24.837 7.1635 32 16 32C24.837 32 32 24.837 32 16C32 7.1635 24.837 0 16 0ZM16 30.0315C8.2805 30.0315 2 23.7195 2 15.9999C2 8.28044 8.2805 1.99994 16 1.99994C23.7195 1.99994 30 8.28047 30 15.9999C30 23.7194 23.7195 30.0315 16 30.0315ZM21.6565 10.3435C21.266 9.953 20.633 9.953 20.2425 10.3435L16 14.586L11.7575 10.3435C11.367 9.953 10.734 9.953 10.343 10.3435C9.9525 10.734 9.9525 11.367 10.343 11.7575L14.5855 16L10.343 20.2425C9.9525 20.6325 9.9525 21.2665 10.343 21.6565C10.7335 22.047 11.3665 22.047 11.7575 21.6565L16 17.414L20.2425 21.6565C20.633 22.047 21.266 22.047 21.6565 21.6565C22.047 21.2665 22.047 20.6325 21.6565 20.2425L17.414 16L21.6565 11.7575C22.0475 11.3665 22.0475 10.7335 21.6565 10.3435Z"
        fill="#ACB2BA"
      />
    </svg>
  );
};

export const Button = ({
  buttonType,
  label,
  className,
  disabled,
  onClick,
  icon,
  iconPosition,
  type,
  loading,
}) => {
  if (buttonType === "primary") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn primary-button ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        {iconPosition === "start" && icon}
        {label}
        {iconPosition === "end" && icon}
      </button>
    );
  } else if (buttonType === "outline-primary") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn cancel-button ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        {iconPosition === "start" && icon}
        {label}
        {iconPosition === "end" && icon}
      </button>
    );
  } else if (buttonType === "download") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn secondary-button ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        {label}
        <FileDownloadOutlinedIcon />
      </button>
    );
  } else if (buttonType === "upload") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn secondary-button ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        {label}
        <FileUploadOutlinedIcon />
      </button>
    );
  } else if (buttonType === "refresh") {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn refresh-btn ${className ?? ""} ${
          loading ? "spinbtn" : ""
        }`}
        onClick={onClick}
        type={type ?? "button"}
      >
        <i className="fa fa-undo" aria-hidden="true"></i>
      </button>
    );
  } else if (buttonType === "close") {
    return (
      <button onClick={onClick} className="close-btn" type="button">
        <CloseIcon />
      </button>
    );
  } else {
    return (
      <button
        disabled={disabled ?? false}
        className={`btn ${className ?? ""}`}
        onClick={onClick}
        type={type ?? "button"}
      >
        {label}
      </button>
    );
  }
};

Button.propTypes = {
  buttonType: propsTypes.string,
  label: propsTypes.string,
  className: propsTypes.string,
  disabled: propsTypes.bool,
  onClick: propsTypes.func,
  icon: propsTypes.element,
  iconPosition: propsTypes.string,
  type: propsTypes.string,
  loading: propsTypes.bool,
};
