export const EXCEL_FILE_FORMATS = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const QUERY_ENUM = {
  BEARER: "Bearer",
  TENANT_ID: "tenant_id",
  BU_ID: "bu_id",
  SNOP_ID: "snop_id",
  SEARCH_STRING: "search_string",
  SORT_KEY: "sort_key",
  SORT_VALUE: "sort_value",
  CURRENT_PAGE_INDEX: "current_page_index",
  CURRENT_PAGE_SIZE: "current_page_size",
  PRODUCT_HIERARCHY_LEVEL: "ph",
  LOCATION_HIERARCHY_LEVEL: "lh",
};

export const SORT_ENUM = {
  ASC: "ASC",
  DESC: "DESC",
};

export const optionRow = [
  { label: "Show 10 Rows", value: 10 },
  { label: "Show 25 Rows", value: 25 },
  { label: "Show 50 Rows", value: 50 },
  { label: "Show 100 Rows", value: 100 },
];

export const optionReview = [
  { label: "Review", value: "reviewed", disabled: true },
  { label: "Review All", value: "allReviewed" },
];

export const filterObj = {
  channel_id: [],
  node_id: [],
  sku_id: [],
  status: [],
};

export const whenSorting = {
  SKU: "sn",
  Node: "n",
  Channel: "cn",
  Status: "st",
};

export const groupLevelFilter = [
  {
    value: "p0",
    label: "pr",
  },
  {
    value: "l0",
    label: "l",
  },
];

export const customSelectTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary25: "#F2F2F2",
      primary: "#E94F1C",
    },
  };
};

export const FORECAST_CATEGORIES = {
  baseline: "BASELINE",
  statistical: "STATISTICAL",
  marketing: "MARKETING",
  operational: "OPERATIONAL",
  sales: "SALES",
  unconstrained: "UNCONSTRAINED",
};

export const headerKey = {
  baseline: "Baseline",
  statistical: "Statistical",
  marketing: "Marketing",
  operational: "Operational",
  sales: "Sales",
  unconstrained: "Unconstrained",
};

export const productKey = {
  pr: "Product",
  l: "Location",
};

export const productLevel = {
  productLevel: "productHierarchyLevel",
  locationLevel: "locationHierarchyLevel",
};

export const HORIZON_FREQUENCY = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  MONTH: "MONTH",
};

export const USER_TYPE = {
  MARKETING: "MARKETING",
  SALES: "SALES",
  OPERATIONAL: "OPERATIONAL",
  UNCONSTRAINED: "UNCONSTRAINED",
};

export const LIGHT_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
  WARNING: "warning",
};

export const FILTER_TYPES = {
  pr: "Products",
  l: "Locations",
  ch: "Channels",
};

export const HIERARCHY_KEY = {
  pr: "p",
  l: "l",
  ch: "c",
};

// forecast upload template
export const UPLOAD_TEMPLATE = ["SKU CODE", "NODE CODE", "CHANNEL"];

export const API_ABRIVN_KEYS_TO_VALUE = {
  de: "detail",
  a: "accuracy",
  ad: "adi",
  atl: "approved_till_level",
  asv: "average_sales_value",
  asvo: "average_sales_volume",
  ci: "channel_id",
  cn: "channel_name",
  c: "cv",
  da: "demandForAccuracy",
  dn1: "demandForN1",
  dn3: "demandForN3",
  es1: "emailsalesmanHierarchyLevel1",
  es2: "emailsalesmanHierarchyLevel2",
  es3: "emailsalesmanHierarchyLevel3",
  es4: "emailsalesmanHierarchyLevel4",
  e: "error",
  fd: "forecast_detail",
  o: "OPERATIONAL",
  s: "STATISTICAL",
  b: "BASELINE",
  sa: "SALES",
  u: "UNCONSTRAINED",
  fdi: "forecast_detail_id",
  fhicr: "forecast_header_id_cr",
  p: "period",
  v: "value",
  vo: "volume",
  fhi: "forecast_header_id",
  in: "is_npi",
  irf: "is_re_forecasted",
  is: "is_seasonal",
  lnms: "last_N_month_sale",
  lasv: "last_actual_sales_value",
  lasvo: "last_actual_sales_volume",
  l1: "locationHierarchyLevel1",
  l2: "locationHierarchyLevel2",
  l3: "locationHierarchyLevel3",
  l4: "locationHierarchyLevel4",
  n1e: "n1Error",
  n3e: "n3Error",
  n: "node",
  ni: "node_id",
  p1: "productHierarchyLevel1",
  p2: "productHierarchyLevel2",
  p3: "productHierarchyLevel3",
  p4: "productHierarchyLevel4",
  p5: "productHierarchyLevel5",
  p6: "productHierarchyLevel6",
  p7: "productHierarchyLevel7",
  s1: "salesmanHierarchyLevel1",
  s2: "salesmanHierarchyLevel2",
  s3: "salesmanHierarchyLevel3",
  s4: "salesmanHierarchyLevel4",
  sg: "segment",
  si: "sku_id",
  sn: "sku_name",
  sp: "sparsity",
  st: "status",
  up: "unit_price",
  ul: "user_level",
  va: "variability",
  n1f: "n1_fidelity",
  n3f: "n3_fidelity",
  et: "exceedThreshold",
  // network
  nk: "network",
  en: "end_node",
  eni: "end_node_id",
  enlt: "end_node_location_type",
  sno: "start_node",
  sni: "start_node_id",
  snlt: "start_node_location_type",
  // filters
  f: "filters",
  l: "locations",
  l0: "locationHierarchyLevel0",
  lb: "label",
  pt: "parent",
  // personnels
  ps: "personnels",
  // products
  pr: "products",
  p0: "productHierarchyLevel0",
  // sales_history
  sh: "sales_history",
  d: "date",
  sea: "salesman_email_address",
  ysvo: "ytd_sales_volume",
  ysv: "ytd_sales_value",
  // local keys
  n1_fidelity: "n1_fidelity",
  n3_fidelity: "n3_fidelity",
  l3sv: "last_3_month_sales_value",
  l3svo: "last_3_month_sales_volume",
  fmr: "fmr",
  ch: "channels",
  c0: "channelLevel0",
  c1: "channelLevel1",
  nnfvo: "next_n_month_volume",
  nnfv: "next_n_month_value",
  nfvo: "next_month_volume",
  nfv: "next_month_value",
  lstd: "life_cycle_start_date",
  pl: "brand",
  nl: "state",
  pel: "nsm",
  lsn: "like_sku_name",
  lsled: "like_sku_phase_out_date",
};

export const API_ABRIVN_KEYS = {
  scenario_name: "name",
  name: "nm",
  detail: "de",
  accuracy: "a",
  adi: "ad",
  approved_till_level: "atl",
  average_sales_value: "asv",
  average_sales_volume: "asvo",
  channel_id: "ci",
  channel_name: "cn",
  cv: "c",
  demandForAccuracy: "da",
  demandForN1: "dn1",
  demandForN3: "dn3",
  emailsalesmanHierarchyLevel1: "es1",
  emailsalesmanHierarchyLevel2: "es2",
  emailsalesmanHierarchyLevel3: "es3",
  emailsalesmanHierarchyLevel4: "es4",
  emailsalesmanHierarchyLevel: "es",
  error: "e",
  forecast_detail: "fd",
  OPERATIONAL: "o",
  STATISTICAL: "s",
  BASELINE: "b",
  SALES: "sa",
  UNCONSTRAINED: "u",
  forecast_detail_id: "fdi",
  period: "p",
  value: "v",
  volume: "vo",
  forecast_header_id: "fhi",
  forecast_header_id_cr: "fhicr",
  is_npi: "in",
  is_re_forecasted: "irf",
  is_seasonal: "is",
  last_N_month_sale: "lnms",
  last_actual_sales_value: "lasv",
  last_actual_sales_volume: "lasvo",
  locationHierarchyLevel1: "l1",
  locationHierarchyLevel2: "l2",
  locationHierarchyLevel3: "l3",
  locationHierarchyLevel4: "l4",
  locationHierarchyLevel: "l",
  n1Error: "n1e",
  n3Error: "n3e",
  node: "n",
  node_id: "ni",
  productHierarchyLevel1: "p1",
  productHierarchyLevel2: "p2",
  productHierarchyLevel3: "p3",
  productHierarchyLevel4: "p4",
  productHierarchyLevel5: "p5",
  productHierarchyLevel6: "p6",
  productHierarchyLevel7: "p7",
  productHierarchyLevel: "p",
  salesmanHierarchyLevel1: "s1",
  salesmanHierarchyLevel2: "s2",
  salesmanHierarchyLevel3: "s3",
  salesmanHierarchyLevel4: "s4",
  salesmanHierarchyLevel: "s",
  segment: "sg",
  sku_id: "si",
  sku_name: "sn",
  sparsity: "sp",
  status: "st",
  unit_price: "up",
  user_level: "ul",
  variability: "va",
  n1_fidelity: "n1f",
  n3_fidelity: "n3f",
  et: "et",
  network: "nk",
  end_node: "en",
  end_node_id: "eni",
  end_node_location_type: "enlt",
  start_node: "sno",
  start_node_id: "sni",
  start_node_location_type: "snlt",
  filters: "f",
  locations: "l",
  locationHierarchyLevel0: "l0",
  label: "lb",
  parent: "pt",
  personnels: "ps",
  products: "pr",
  productHierarchyLevel0: "p0",
  sales_history: "sh",
  date: "d",
  salesman_email_address: "sea",
  logDetails: "ld",
  createdAt: "ca",
  isRead: "ir",
  id: "id",
  ytd_sales_volume: "ysvo",
  ytd_sales_value: "ysv",
  fmr: "fmr",
  channels: "ch",
  channelLevel0: "c0",
  channelLevel1: "c1",
  last_3_month_sales_value: "l3sv",
  last_3_month_sales_volume: "l3svo",
  next_n_month_volume: "nnfvo",
  next_n_month_value: "nnfv",
  next_month_volume: "nfvo",
  next_month_value: "nfv",
  life_cycle_start_date: "lstd",
  brand: "pl",
  state: "nl",
  nsm: "pel",
  like_sku_name: "lsn",
  like_sku_phase_out_date: "lsled",
  remark_code: "rc",
  remark_description: "rd",
};

export const ForecastStatus = {
  OPEN: "OPEN",
  REVIEWED: "REVIEWED",
  CLOSED: "CLOSED",
  PARTIALLY_OPENED: "PARTIALLY OPENED",
};

export const statusOption = [
  { value: ForecastStatus.OPEN, label: "Open" },
  { value: ForecastStatus.REVIEWED, label: "Reviewed" },
  { value: ForecastStatus.CLOSED, label: "Closed" },
];

export const disabledOnStatus = {
  [ForecastStatus.REVIEWED]: true,
  [ForecastStatus.CLOSED]: true,
};

export const MAX_INPUT_LIMIT = 10000000000; 
