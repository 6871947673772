import Stack from "@mui/material/Stack";
import { createDemandSummary } from "../../helper/table-helper";
import { headerKey } from "../../utils/Constant";
import CusAccordion from "../cus-accordion";
import { BorderBox, TitleTypo } from "../reuse-comp-fn";
import SummaryTable from "../tables/summary-table";

function DemandSummary({ data }) {
  let summaryObj = {};
  if (Object.keys(data || {})?.length > 0) {
    summaryObj = createDemandSummary(data);
  }

  if (Object.keys(summaryObj)?.length === 0) return null;

  return (
    <>
      <BorderBox>
        <TitleTypo>Demand Summary</TitleTypo>
        <Stack gap={1} mt={1}>
          {Object.keys(summaryObj)?.map((item, index) => {
            return (
              <CusAccordion title={headerKey[item]} key={index}>
                <SummaryTable
                  title={headerKey[item]}
                  header={summaryObj[item].header || []}
                  body={summaryObj[item].body || []}
                />
              </CusAccordion>
            );
          })}
        </Stack>
      </BorderBox>
    </>
  );
}

export default DemandSummary;
