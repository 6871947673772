/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/breadcrumb";
import DemandSummary from "../../components/demand-summary";
import Filter from "../../components/filterComponent";
import Loader from "../../components/loader";
import { filterMarketing } from "../../services/marketing";
import {
  fetchFilterData,
  selectGlobalConfig,
  setScreenType,
} from "../../store/slices/globalConfig";
import { selectProgress } from "../../store/slices/loading";
import {
  fetchSummaryData,
  getMarketingSlice,
  selectMarketing,
} from "../../store/slices/marketing";
import { USER_TYPE, filterObj } from "../../utils/Constant";
import "../style.scss";
import GridTable from "./grid-table";

function Marketing() {
  const dispatch = useDispatch();
  const { isProgress } = useSelector(selectProgress);
  const { filterData } = useSelector(selectGlobalConfig);
  const { summaryData, filterObj: filterDa } = useSelector(selectMarketing);

  useEffect(() => {
    dispatch(setScreenType({ screenType: USER_TYPE.MARKETING }));
    dispatch(fetchFilterData({ func: filterMarketing }));
  }, []);

  useEffect(() => {
    dispatch(fetchSummaryData({ filterObj: filterDa }));
  }, [filterDa]);

  const applyCallback = (filterObj) => {
    dispatch(getMarketingSlice({ filterObj, pageIndex: 1 }));
  };

  const clearCallback = () => {
    dispatch(getMarketingSlice({ filterObj, pageIndex: 1 }));
  };
  
  return (
    <Box className="marketing-container">
      <Loader isShow={isProgress} />
      <Grid item md={12} sm={12} xs={12}>
        <h3 className="section-heading"> {"Forecasting"}</h3>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Breadcrumb list={["Product Planning", "Forecasting"]} />
      </Grid>
      {Object.keys(filterData ?? {}).length > 0 && (
        <Filter
          dynamicResponse={filterData}
          dropDownTypes={Object.keys(filterData)}
          applyCallback={applyCallback}
          clearCallback={clearCallback}
        />
      )}
      <DemandSummary data={summaryData} />
      <GridTable />
    </Box>
  );
}

export default Marketing;
