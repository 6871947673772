import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isProgress: false,
}

export const ProgressSlice = createSlice({
    name: "progress",
    initialState: initialState,
    reducers: {
        loadingProgress: (state, action) => {
            state.isProgress = action.payload.isProgress;
        }
    }
})

export const { loadingProgress } = ProgressSlice.actions;

export default ProgressSlice.reducer;

export const selectProgress = (state) => state.ProgressReducer;