export const optionReviewFun = ({ optionReview, selectedLn }) => {
  if (selectedLn > 0) {
    let newOption = optionReview.filter((item) => item.value !== "reviewed");
    return [
      { label: "Review", value: "reviewed", disabled: false },
      ...newOption,
    ];
  } else {
    let newOption = optionReview.filter((item) => item.value !== "reviewed");
    return [
      { label: "Review", value: "reviewed", disabled: true },
      ...newOption,
    ];
  }
};

export const isRejectButtonDisabled = ({ selectedData, bodyData }) => {
  if (selectedData.length === 0) return true;
  let map = new Map();
  selectedData.forEach((item) => {
    map.set(item, true);
  });

  let ln = bodyData.length;
  for (let i = 0; i < ln; i++) {
    let item = bodyData[i];
    if (map.get(item?.fhi) && item?.atl === 0) {
      return true;
    }
  }

  return false;
};

export const isInvalidKeyDown = (e) => {
  return ["e", "E", "+", "-"].includes(e.key);
};
