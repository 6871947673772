import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./store/reducer";
import createSagaMiddleware from "@redux-saga/core";
import { watcherSaga } from "./store/sagas";
import Env from "./utils/Env";
let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

// const persistConfig = {
//   key: "root",
//   storage
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  devTools: Env.MODE !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
});

// export const persistor = persistStore(store);
sagaMiddleware.run(watcherSaga);
