import { getLocalStorage } from "../helper/localStorage";
import { CONFIGURATION_KEY } from "../utils/ConfigurationKey";

const { USER, TOKEN, SNOP, CONFIGURATION, SELECTED_BUSINESS_UNIT } = CONFIGURATION_KEY;

export const user = getLocalStorage(USER);
export const tenantId = user.tenant_id;
export const selected_business_unit = getLocalStorage(SELECTED_BUSINESS_UNIT);
export const buId = selected_business_unit.business_unit_id;
export const token = getLocalStorage(TOKEN);
export const permissions = user.permissions;
export const userId = user.user_id;
export const configuration = getLocalStorage(CONFIGURATION);
export const snop = getLocalStorage(SNOP);
export const isUnconstrained =
  permissions && permissions.some((val) => val === "FORECAST_VIEW");

//configuration sales
export const localeConfiguration =
  (configuration && configuration?.locale) ?? {};
export const currencySymbol =
  (configuration && configuration?.locale?.baseCurrencySymbol) ?? "";
export const localeDateFormat =
  localeConfiguration?.dateFormat?.toUpperCase() || "YYYY-MM-DD";
export const salesHistoryConsideration =
  (configuration && configuration?.forecast?.salesHistoryConsideration) ?? 0;
export const planningHorizon =
  (configuration && configuration?.snop?.planningHorizon) ?? 0;
export const planningCycleFrequency = (
  (configuration && configuration?.snop?.planningCycleFrequency) ??
  ""
).toUpperCase();
export const forecastUpperBound =
  (configuration && configuration?.forecast?.confidenceUpperBound) ?? 0;
export const forecastLowerBound =
  (configuration && configuration?.forecast?.confidenceLowerBound) ?? 0;
export const locationHierarchy =
  (configuration && configuration?.entities?.locationHierarchy) ?? {};
export const locationHierarchyNoOfLevels =
  (configuration &&
    configuration?.entities?.locationHierarchy.locationHierarchyNoOfLevels) ??
  0;
export const productHierarchyNoOfLevels =
  (configuration &&
    configuration?.entities?.productHierarchy.productHierarchyNoOfLevels) ??
  0;
export const salesmanHierarchyNoOfLevels =
  (configuration &&
    configuration?.entities?.salesmanHierarchy.salesmanHierarchyNoOfLevels) ??
  0;
export const locationForecastLevel =
  (configuration && configuration?.forecast?.locationForecastLevel) ?? 0;

export const productForecastLevel =
  (configuration && configuration?.forecast?.productForecastLevel) ?? 0;

export const adiForecastLevel =
  (configuration && configuration?.forecast?.adi) ?? 1.32;

export const cvForecastLevel =
  (configuration && configuration?.forecast?.cv) ?? 0.49;
export const salesmanHierarchy =
  (configuration && configuration?.entities?.salesmanHierarchy) ?? {};

export const demandPlannerHierarchyNoOfLevels =
  (configuration &&
    configuration?.entities?.demandPlannerHierarchy
      .demandPlannerHierarchyNoOfLevels) ??
  0;
export const demandPlannerHierarchy =
  (configuration && configuration?.entities?.demandPlannerHierarchy) ?? {};
export const errorSelectionCriteria =
  (configuration && configuration?.forecast?.errorSelectionCriteria) ??
  "" ??
  "";
export const snopConfiguration = (configuration && configuration.snop) || {};
export const forecastConfiguration =
  (configuration && configuration?.forecast) ?? {};
export const productConfiguration =
  (configuration && configuration?.entities?.productHierarchy) ?? {};
export const locationConfiguration =
  (configuration && configuration?.entities?.locationHierarchy) ?? {};
export const salesmanConfiguration =
  (configuration && configuration?.entities?.salesmanHierarchy) ?? {};
export const productHierarchy =
  (configuration && configuration?.entities?.productHierarchy) ?? {};

export const defaultProductGroupByLevel =
  (configuration && configuration?.forecast?.defaultProductGroupByLevel) ?? 0;
export const defaultLocationGroupByLevel =
  (configuration && configuration?.forecast?.defaultLocationGroupByLevel) ?? 0;
export const defaultPersonnelHierarchyLevel = undefined;

export const remarkConfiguredReason =
  (configuration && configuration?.forecast?.remarks) ?? {};
export const threshold =
  (configuration && configuration?.forecast?.threshold) ?? {};

export const language =
  (configuration && configuration.locale.language) ?? "en-US";
export const from_date = snop?.from_date;
export const to_date = snop?.to_date;
export const snopDemandReviewDate = snop?.demand_review_date;
export const snopId = snop?.snop_id || "";
export const features = configuration && configuration?.transactional?.features;
export const isGroupRecordsEditable =
  (configuration && configuration?.forecast?.isGroupedDataEditable) ?? false;
export const enrichment =
  (configuration && configuration?.forecast?.enrichmentWindow) ?? {};

export const enrichmentWindow = {
  operational: enrichment?.operational ?? 0,
  sales: enrichment?.sales ?? 0,
  unconstrained: enrichment?.unconstrained ?? 0,
};
export const productPriceFeature =
  configuration && configuration?.transactional?.features?.productPriceFeature;

export const isExcelReportEnabled = false;
export const forecastAggregation =
  (configuration && configuration?.forecast?.disaggregationComparison) ??
  "sales"; // accepted values - sales,prevforecast,statistical

export const forecastThreshold = (threshold && threshold?.limits) ?? {};
export const thresholdComparisonKey =
  (threshold && threshold?.comparison) ?? "STATISTICAL";
export const useFeaturesInForecast =
  (configuration && configuration?.forecast?.useFeaturesInForecast) ?? [];
export const isNpiEditable =
  (configuration && configuration?.forecast?.isNpiEditable) ?? false;

export const NPIDays = (configuration && configuration?.forecast?.NPIDays) ?? 0;

// marketing configuration
export const marketingDays =
  +(configuration && configuration?.product?.editableDays) ?? 120;

export const marketingHierarchyNoOfLevels =
  (configuration &&
    configuration?.entities?.marketingHierarchy?.marketingHierarchyNoOfLevels) ??
  4;
export const marketingHierarchy =
  (configuration && configuration?.entities?.marketingHierarchy) ?? {};
