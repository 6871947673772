import { useState } from "react";
import { Button } from "../button";
import { toastMsg } from "../../helper/toast-helper";
import propTypes from "prop-types";
import ERROR_MSG from "../../utils/ErrorMsg";
import { EXCEL_FILE_FORMATS, LIGHT_TYPE } from "../../utils/Constant";
import STATUS_CODE from "../../utils/StatusCode";
import { downloadCsv } from "../../services/common";

function CsvDownload({ type, url, fileName }) {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      const { data, status } = await downloadCsv({ type, url });
      if (
        STATUS_CODE.OK === status &&
        EXCEL_FILE_FORMATS.includes(data?.type)
      ) {
        const url = window.URL.createObjectURL(new Blob([data]));
        anchor.href = url;
        anchor.download = `${fileName}.xlsx` || "file.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      } else {
        toastMsg(LIGHT_TYPE.ERROR, ERROR_MSG.SWR);
      }
      setLoading(false);
    } catch (e) {
      toastMsg(LIGHT_TYPE.ERROR, e?.message || ERROR_MSG.SWR);
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={loading}
      onClick={handleClick}
      loading={loading}
      buttonType={!loading ? "download" : "refresh"}
    />
  );
}

export default CsvDownload;

CsvDownload.propTypes = {
  fileName: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  url: propTypes.string.isRequired,
};
