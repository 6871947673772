import InputField from "../input-field";
import { useEffect, useState } from "react";
import { Button } from "../button";
import { Stack } from "@mui/material";
import propTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

function SearchFrom({ handleOnSearch }) {
  const [search, setSearch] = useState("");

  useEffect(() => {
    let value = search.trim();
    if (value === "") {
      handleOnSearch("");
    }
  }, [search]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSubmit = (e) => {
    let value = search.trim();
    handleOnSearch(value);
    e.preventDefault();
  };

  const handleClear = () => {
    setSearch("");
    handleOnSearch("");
  };

  return (
    <Stack
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      flexDirection="row"
      gap={1}
    >
      <InputField
        focused
        label="Search"
        value={search}
        onChange={handleSearch}
        endAdornmentClear={
          <InputAdornment position="end">
            {search && (
              <IconButton onClick={handleClear} sx={{ p: 0 }}>
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
      <Button type="submit" buttonType="primary" label="Go" />
    </Stack>
  );
}

export default SearchFrom;

SearchFrom.propTypes = {
  handleOnSearch: propTypes.func.isRequired,
};
