import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import propTypes from "prop-types";

const StyledTableCell = styled((props) => <TableCell {...props} />)(
  ({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      fontSize: 14,
      fontWeight: 600,
      border: `1px solid ${theme.palette.input.border}`,
      minWidth: 100,
      zIndex: 3,
      // "&.stickyCol": {
      //   zIndex: 4,
      // },
      // "&.stickyCol-0": {
      //   left: 0,
      // },
      // "&.stickyCol-1": {
      //   left: 67,
      // },
      // "&.stickyCol-2": {
      //   left: 170,
      // },
      // "&.stickyCol-3": {
      //   left: 273.9,
      // },
      // "&.stickyCol-4": {
      //   left: 386,
      // },
      // "&.stickyCol-5": {
      //   left: 489.5,
      // },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      color: theme.palette.input.main,
      border: `1px solid ${theme.palette.input.border}`,
      backgroundColor: theme.palette.common.white,
      minWidth: 100,
      // "&.stickyCol": {
      //   zIndex: 2,
      //   position: "sticky",
      // },
      // "&.stickyCol-0": {
      //   left: 0,
      // },
      // "&.stickyCol-1": {
      //   left: 67,
      // },
      // "&.stickyCol-2": {
      //   left: 170,
      // },
      // "&.stickyCol-3": {
      //   left: 273.9,
      // },
      // "&.stickyCol-4": {
      //   left: 386,
      // },
      // "&.qty-cell": {
      //   left: 489.5,
      // },
    },
  })
);

function TabCell({ children, ...props }) {
  return <StyledTableCell {...props}>{children}</StyledTableCell>;
}

export default TabCell;

TabCell.propTypes = {
  children: propTypes.node,
};
