import { Box } from "@mui/material";
import PropTypes from "prop-types";

const Logs = ({ start, end, total, title }) => {
  return (
    <Box
      component="h1"
      sx={{ fontSize: "1.5rem", fontWeight: 500, color: "secondary.main" }}
    >
      {start} - {end} of {total} {title}
    </Box>
  );
};

export default Logs;

Logs.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  total: PropTypes.number,
  title: PropTypes.string,
};
