import { useSelector } from "react-redux";
import { selectGlobalConfig } from "../../store/slices/globalConfig";
import CusTooltip from "../cus-tooltip";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { createUserLevel, getUserReviewStatus } from "../../helper/user-helper";
import propTypes from "prop-types";
function ApproveTillLevel({ atl }) {
  const { screenType } = useSelector(selectGlobalConfig);
  const userAllLevel = createUserLevel(screenType);
  const userLevel = getUserReviewStatus(atl, userAllLevel);
  
  return (
    <Stack flexDirection="row" gap={0.5} justifyContent="center">
      {Object.keys(userLevel).map((lev, index) => {
        return (
          <CusTooltip title={lev} key={index}>
            <Avatar
              sx={{
                width: 11,
                height: 11,
                background: (theme) =>
                  userLevel[lev]
                    ? theme.palette.myCommon.review
                    : theme.palette.myCommon.reviewDisabled,
              }}
            >
              <CheckIcon sx={{ fontSize: 10 }} />
            </Avatar>
          </CusTooltip>
        );
      })}
    </Stack>
  );
}

export default ApproveTillLevel;

ApproveTillLevel.propTypes = {
  atl: propTypes.number.isRequired,
};
