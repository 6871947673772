import {
  demandPlannerHierarchy,
  demandPlannerHierarchyNoOfLevels,
  locationConfiguration,
  locationForecastLevel,
  locationHierarchy,
  locationHierarchyNoOfLevels,
  marketingHierarchy,
  marketingHierarchyNoOfLevels,
  productConfiguration,
  productForecastLevel,
  productHierarchy,
  productHierarchyNoOfLevels,
  salesmanHierarchy,
  salesmanHierarchyNoOfLevels,
} from "../config";
import { productLevel } from "../utils/Constant";

export const hierarchyLevels = {
  MARKETING: marketingHierarchyNoOfLevels,
  SALES: salesmanHierarchyNoOfLevels,
  OPERATIONAL: demandPlannerHierarchyNoOfLevels,
  UNCONSTRAINED: demandPlannerHierarchyNoOfLevels,
  PRODUCT: productHierarchyNoOfLevels,
  LOCATION: locationHierarchyNoOfLevels
};

export const hierarchyLevel = {
  MARKETING: "marketingHierarchyLevel",
  SALES: "salesmanHierarchyLevel",
  OPERATIONAL: "demandPlannerHierarchyLevel",
  UNCONSTRAINED: "demandPlannerHierarchyLevel",
  PRODUCT: "productHierarchyLevel",
  LOCATION: "locationHierarchyLevel",
  BASE: "base",
};

export const getHierarchyLevel = {
  MARKETING: marketingHierarchy,
  SALES: salesmanHierarchy,
  OPERATIONAL: demandPlannerHierarchy,
  UNCONSTRAINED: demandPlannerHierarchy,
  PRODUCT: productHierarchy,
  LOCATION: locationHierarchy
};

export const createUserLevel = (userType) => {
  let level = [];
  for (let i = 1; i <= hierarchyLevels[userType]; i++) {
    let key = getHierarchyLevel[userType][hierarchyLevel[userType] + i];
    if (key) level.push(key);
  }
  return level;
};

export const getUserReviewStatus = (atl, userAllLevel) => {
  let reviewLevel = {};
  userAllLevel.forEach((item, index) => {
    if (index < atl) {
      reviewLevel[item] = true;
    } else {
      reviewLevel[item] = false;
    }
  });

  return reviewLevel;
};

export const checkSalesmanHierarchy = () => {
  return !!+salesmanHierarchyNoOfLevels;
};

export const dynamicLabels = (val) => {
  const keys = val.replace(/\d$/, "");
  const level = val.replace(/[^\d]/g, "");
  if (keys === "p") {
    if (level === "0") {
      return productForecastLevel > 0
        ? productConfiguration[keys + productForecastLevel]
        : productConfiguration["base"] ?? val;
    }
    return productConfiguration[productLevel.productLevel + level] ?? val;
  } else if (keys === "l") {
    if (level === "0") {
      return locationForecastLevel > 0
        ? locationConfiguration[keys + locationForecastLevel]
        : locationConfiguration["base"] ?? val;
    }
    return locationConfiguration[productLevel.locationLevel + level] ?? val;
  }
  return val;
};

export const groupFilterLabels = ({ filterData }) => {
  return (
    Object.keys(filterData ?? {}).length > 0 &&
    Object.keys(filterData ?? {})
      .filter((d) => {
        const HierarchyLevel = checkSalesmanHierarchy();
        return d === "ps" ? HierarchyLevel : true;
      })
      .reduce((acc, item) => {
        if (["pr", "l"].indexOf(item) >= 0) {
          let allHierachies = Object.keys(filterData[item] ?? {});
          if (item === "pr" && productForecastLevel > 0) {
            allHierachies = [];
            for (
              let level = productForecastLevel + 1;
              level <= productHierarchyNoOfLevels;
              level++
            ) {
              allHierachies.push(`p${level}`);
            }
            allHierachies.reverse();
            allHierachies.push("p0");
          } else if (item === "l" && locationForecastLevel > 0) {
            allHierachies = [];
            for (
              let level = locationForecastLevel + 1;
              level <= locationHierarchyNoOfLevels;
              level++
            ) {
              allHierachies.push(`l${level}`);
            }
            allHierachies.reverse();
            allHierachies.push("l0");
          }
          acc[item] = allHierachies;
        }
        return acc;
      }, {})
  );
};
