import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastMsg } from "../../../helper/toast-helper";
import { LIGHT_TYPE } from "../../../utils/Constant";
import ERROR_MSG from "../../../utils/ErrorMsg";
import STATUS_CODE from "../../../utils/StatusCode";

const initialState = {
  screenType: "",
  filterData: {},
  isForecasting: true,
};

export const fetchFilterData = createAsyncThunk(
  "GlobalConfig/fetchFilterData",
  async ({ func }) => {
    try {
      const { status, data } = await func();
      if (status === STATUS_CODE.OK) {
        let filterData = { ...data?.responseData?.data?.f };
        delete filterData.st;
        return filterData || {};
      } else {
        toastMsg(LIGHT_TYPE.ERROR, ERROR_MSG.SWR);
      }
      return {};
    } catch (e) {
      toastMsg(LIGHT_TYPE.ERROR, e.message || ERROR_MSG.SWR);
    }
    return {};
  },
  {
    condition: (_, { getState }) => {
      const { filterData } = getState().GlobalConfigReducer;
      if (Object.keys(filterData).length === 0) {
        return true;
      }
      return false;
    },
  }
);

const GlobalConfigSlice = createSlice({
  name: "GlobalConfig",
  initialState: initialState,
  reducers: {
    setScreenType: (state, action) => {
      state.screenType = action.payload.screenType;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload.filterData;
    },
    setForecasting: (state, action) => {
      state.isForecasting = action.payload.isForecasting;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilterData.fulfilled, (state, action) => {
        state.filterData = action.payload;
      })
      .addCase(fetchFilterData.rejected, (state, action) => {
        state.filterData = {};
      });
  },
});

export const { setScreenType, setFilterData, setForecasting } =
  GlobalConfigSlice.actions;

export default GlobalConfigSlice.reducer;

export const selectGlobalConfig = (state) => state.GlobalConfigReducer;
