import { Route, Routes } from "react-router-dom";
import ErrorPage from "../components/errorPage";
import { useEffect } from "react";
import Marketing from "../pages/marketing";
import RouteEndPoint from "../utils/RouteEndPoint";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import { user } from "../config";

const Routing = () => {
  let navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      navigate("/error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box sx={{ px: 1, py: .5 }}>
      <Routes>
        <Route path={RouteEndPoint.MARKETING} element={<Marketing />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Box>
  );
};

export default Routing;
