import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
// import React from "react";

// render micro frontend function
window.rendercreatereactapp = (containerId, history) => {
  ReactDOM.createRoot(
    <App history={history} />,
    document.getElementById(containerId)
  );
};

// unmount micro frontend function
window.unmountcreatereactapp = (containerId) => {
  root.unmount(document.getElementById(containerId));
};

const root = ReactDOM.createRoot(document.getElementById("product"));

root.render(
  // <React.StrictMode>
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>
  // {/* </React.StrictMode> */}
);
