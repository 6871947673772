import { put, takeEvery } from "redux-saga/effects";
import { GET_MARKETING } from "../../types";
import { getMarketingSlice, progressMarketing } from "../../slices/marketing";
import { LIGHT_TYPE } from "../../../utils/Constant";
import { getMarketing } from "../../../services/marketing";
import { createHeaderData } from "../../../helper/table-helper";
import STATUS_CODE from "../../../utils/StatusCode";
import { toastMsg } from "../../../helper/toast-helper";
import ERROR_MSG from "../../../utils/ErrorMsg";
import { loadingProgress } from "../../slices/loading";

function* getMarketingSaga(action) {
  yield put(
    progressMarketing({ isProgress: true, progressMsg: LIGHT_TYPE.LOADING })
  );
  yield put(loadingProgress({ isProgress: true }));
  try {
    const { data, status } = yield getMarketing(action.payload);
    if (status === STATUS_CODE.OK) {
      let res = data?.responseData?.data;
      const notificationMsg = action.payload?.notificationMsg;
      const headerData = createHeaderData(res.de);
      yield put(
        getMarketingSlice({
          headerData,
          bodyData: res.de,
          totalPage: res.total_records,
          ...(notificationMsg && { selectedData: [] }),
        })
      );
      if (notificationMsg) toastMsg(LIGHT_TYPE.SUCCESS, notificationMsg);
    } else {
      toastMsg(LIGHT_TYPE.ERROR, ERROR_MSG.SWR);
    }
    yield put(
      progressMarketing({ isProgress: false, progressMsg: LIGHT_TYPE.LOADING })
    );
    yield put(loadingProgress({ isProgress: false }));
  } catch (error) {
    toastMsg(LIGHT_TYPE.ERROR, ERROR_MSG.SWR);
    yield put(
      progressMarketing({ isProgress: false, progressMsg: LIGHT_TYPE.ERROR })
    );
    yield put(loadingProgress({ isProgress: false }));
  }
}

export function* watchMarketingSaga() {
  yield takeEvery(GET_MARKETING, getMarketingSaga);
}
