/* eslint-disable react/prop-types */
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { tableCellClasses } from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import TabCell from "./tab-cell";
import TabRow from "./tab-row";
import HeaderContentSort from "./header-content-sort";
import { FORECAST_CATEGORIES, headerKey } from "../../utils/Constant";
import { dateFormat } from "../../helper/date-helper";
import {
  findTableObj,
  formatTableBody,
  getStickyCol,
  isDisabledFunc,
  isDisabledOnLoading,
  selectDisabledFunc,
} from "../../helper/table-helper";
import { CaptionTypo } from "../reuse-comp-fn/index";
import InputNumeric from "../intput-numeric";
import CheckBox from "@mui/material/Checkbox";
import propsTypes from "prop-types";
import ApproveTillLevel from "../approve-till-level";
import { Fragment } from "react";
import Status from "../status";
import CusTooltip from "../cus-tooltip";
import COMMON_MSG from "../../utils/CommonMsg";

const Tables = ({
  headerData,
  bodyData,
  sortKey,
  sortDir,
  handleBlur,
  handleSort,
  hashMapSaveStatus,
  whenSorting,
  isGroupFilterApply,
  handleOnCheckboxChange,
  selectedData,
}) => {
  const headerObjForecast = findTableObj(headerData);

  return (
    <TableContainer sx={{ maxHeight: "80vh" }}>
      <Table
        size="small"
        stickyHeader
        sx={{ background: (theme) => theme.palette.input.border }}
      >
        <TableHead>
          <TabRow>
            {headerData.map((header, index) => {
              let className = getStickyCol(index);
              if (typeof header === "string") {
                if (!header)
                  return (
                    <TabCell
                      sx={{
                        ...(index === 0 && { minWidth: "32px !important" }),
                      }}
                      rowSpan={2}
                      key={index}
                      className={className}
                    />
                  );
                return (
                  <TabCell
                    rowSpan={2}
                    key={index}
                    className={className}
                    onClick={() => handleSort(header)}
                  >
                    <HeaderContentSort
                      title={header}
                      sortKey={whenSorting[header]}
                      sortDir={sortDir}
                      column={sortKey}
                      isSorting={header in whenSorting}
                    />
                  </TabCell>
                );
              } else {
                return Object.keys(header).map((key, index) => {
                  let colSpan = header[key].length;
                  return (
                    <TabCell align="center" colSpan={colSpan} key={key + index}>
                      {headerKey[key]}
                    </TabCell>
                  );
                });
              }
            })}
          </TabRow>
          <TabRow
            sx={{
              [`& .${tableCellClasses.stickyHeader}`]: {
                top: 29,
              },
            }}
          >
            {headerData.map((header) => {
              if (typeof header === "object") {
                return Object.keys(header).map((key) => {
                  return header[key].map((item, index) => {
                    return (
                      <TabCell align="center" key={item + index}>
                        {dateFormat(item)}
                      </TabCell>
                    );
                  });
                });
              } else {
                return null;
              }
            })}
          </TabRow>
        </TableHead>
        <TableBody>
          {bodyData.map((item, index) => {
            let getFormattedData = formatTableBody(item.fd, headerObjForecast);
            const isSelectDisabled = selectDisabledFunc({
              fhi: item.fhi,
              isGroupFilterApply,
              status: item.st,
            });

            return (
              <Fragment key={index}>
                <TabRow key={index + item.n}>
                  <TabCell
                    rowSpan={2}
                    className={getStickyCol(0)}
                    sx={{
                      minWidth: "32px !important",
                    }}
                  >
                    {item.fhi ? (
                      <CheckBox
                        checked={selectedData.includes(item.fhi)}
                        disabled={isSelectDisabled}
                        onChange={(e) => handleOnCheckboxChange(e, item.fhi)}
                      />
                    ) : (
                      <CusTooltip title={COMMON_MSG.NAR}>
                        <Box component="span">
                          <CheckBox disabled={isSelectDisabled} />
                        </Box>
                      </CusTooltip>
                    )}
                  </TabCell>
                  <TabCell rowSpan={2} className={getStickyCol(1)}>
                    {item.sn}
                  </TabCell>
                  <TabCell rowSpan={2} className={getStickyCol(2)}>
                    {item.n}
                  </TabCell>
                  <TabCell rowSpan={2} className={getStickyCol(3)}>
                    {item.cn}
                  </TabCell>
                  <TabCell rowSpan={2} className={getStickyCol(4)}>
                    <Status status={item.st} />
                  </TabCell>
                  <TabCell align="center" className="stickyCol qty-cell">
                    <CaptionTypo>Volume</CaptionTypo>
                  </TabCell>
                  {Object.keys(getFormattedData).map((key) => {
                    return getFormattedData[key].map((innerItem, arrIdx) => {
                      if (key === FORECAST_CATEGORIES.marketing.toLowerCase()) {
                        let uniqueKey = `${item.si}-${item.ni}-${item.ci}`;
                        let statusKey = `${uniqueKey}-${innerItem.ft}-${innerItem.p}`;
                        let lightType = hashMapSaveStatus[statusKey];
                        let isDisabledOnLoad = isDisabledOnLoading({
                          lightType,
                        });
                        let isDisabled = isDisabledFunc({
                          period: innerItem.p,
                          lstd: item.lstd,
                        });

                        return (
                          <TabCell key={uniqueKey + arrIdx}>
                            {innerItem.isForecastAvl ? (
                              innerItem.vo === null && isDisabled ? (
                                "-"
                              ) : (
                                <InputNumeric
                                  value={innerItem.vo}
                                  displayType={isDisabled ? "text" : "input"}
                                  isLight
                                  lightType={lightType}
                                  onBlur={
                                    !isDisabled &&
                                    function (e) {
                                      handleBlur({
                                        value: e.target.value,
                                        item: innerItem,
                                        si: item.si,
                                        ni: item.ni,
                                        ci: item.ci,
                                        fhi: item.fhi,
                                      });
                                    }
                                  }
                                  disabled={
                                    isDisabled ||
                                    isDisabledOnLoad ||
                                    isGroupFilterApply
                                  }
                                />
                              )
                            ) : (
                              "-"
                            )}
                          </TabCell>
                        );
                      }
                      return (
                        <TabCell key={arrIdx + key}>
                          {innerItem.isForecastAvl && innerItem.vo !== null ? (
                            <InputNumeric
                              value={innerItem.vo}
                              displayType="text"
                            />
                          ) : (
                            "-"
                          )}
                        </TabCell>
                      );
                    });
                  })}
                  <TabCell align="center" rowSpan={2}>
                    <ApproveTillLevel atl={item.atl} />
                  </TabCell>
                </TabRow>
                <TabRow key={index + item.sn}>
                  <TabCell align="center" className="stickyCol qty-cell">
                    <CaptionTypo>Value</CaptionTypo>
                  </TabCell>
                  {Object.keys(getFormattedData).map((key) => {
                    return getFormattedData[key].map((item, arrIdx) => {
                      return (
                        <TabCell key={key + arrIdx}>
                          {item.isForecastAvl && item.v !== null ? (
                            <InputNumeric value={item.v} displayType="text" />
                          ) : (
                            "-"
                          )}
                        </TabCell>
                      );
                    });
                  })}
                </TabRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Tables;

Tables.propsTypes = {
  headerData: propsTypes.array,
  bodyData: propsTypes.array,
  handleBlur: propsTypes.func,
  handleSort: propsTypes.func,
  sortKey: propsTypes.string,
  sortDir: propsTypes.string,
  hashMapSaveStatus: propsTypes.object,
  whenSorting: propsTypes.object,
  isGroupFilterApply: propsTypes.bool,
};
