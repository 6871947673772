export const saveMarketingPayload = ({ value, item, ni, si, ci, fhi }) => {
  let isAvl = item.isAvl;
  const { p, ft, fdi } = item;
  let mainObj = [];
  let mainAr = { ni, si, ci, ...(isAvl && { fhi }) };
  let arr = [];
  let obj = {
    vo: +value,
    p,
    ft,
    ...(isAvl && { fdi }),
  };
  arr.push(obj);
  mainAr.fd = arr;
  mainObj.push(mainAr);
  return mainObj;
};

export const getHierarchyLevelPayload = (groupLevelFilter) => {
  let ph = 0;
  let lh = 0;
  if (groupLevelFilter.length > 0) {
    ph = groupLevelFilter[0].value.slice(-1);
    lh = groupLevelFilter[1].value.slice(-1);
  }
  return {
    ph: +ph,
    lh: +lh,
  };
};
