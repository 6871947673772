import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    storeForecastFilterbarData: [],
};

export const FilterbarSlice = createSlice({
    name: "filterBar",
    initialState: initialState,
    reducers: {
        setForecastFilterbarSlice: (state, action) => {
            state.storeForecastFilterbarData = action.payload;
        },
        resetForecastFilterbarSlice: () => {
            let initialStateOfValues = { ...initialState };
            initialStateOfValues.storeForecastFilterbarData = [];
            return initialStateOfValues;
        },
    },
});

export const {
    setForecastFilterbarSlice,
    resetForecastFilterbarSlice,
} = FilterbarSlice.actions;

export default FilterbarSlice.reducer;
