import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import propType from "prop-types";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.input.sideBorder}`,
  borderRadius: 5,
  "&:before": {
    position: "unset",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    borderRadius: 5,
    borderBottom: `1px solid ${theme.palette.input.sideBorder}`,
    minHeight: 40,
    fontWeight: theme.typography.fontWeightBold,
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function CusAccordion({ title, children, ...otherProps }) {
  return (
    <Accordion defaultExpanded {...otherProps}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

CusAccordion.propTypes = {
  title: propType.string,
  children: propType.node,
};
