import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { CaptionTypo } from "../reuse-comp-fn";
import propTypes from "prop-types";
import { dateFormat } from "../../helper/date-helper";
import InputNumeric from "../intput-numeric";
import { currencySymbol } from "../../config";

const StyledTableCell = styled((props) => <TableCell {...props} />)(
  ({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      fontSize: 11,
      fontWeight: 600,
      borderRight: `1px solid ${theme.palette.input.border}`,
      borderBottom: `1px solid ${theme.palette.input.border}`,
      minWidth: 115,
      maxWidth: 115,
      "&:last-child": {
        borderRight: 0,
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      fontWeight: 600,
      color: theme.palette.myCommon.lily,
      borderRight: `1px solid ${theme.palette.input.border}`,
      borderBottom: `1px solid ${theme.palette.input.border}`,
      minWidth: 115,
      maxWidth: 115,
      "&:last-child": {
        borderRight: 0,
      },
    },
  })
);

const StyledTableRow = styled(TableRow)(() => ({
  "&:last-child td": {
    borderLeft: 0,
    borderTop: 0,
    borderBottom: 0,
  },
}));

function SummaryTable({ title, header, body }) {
  return (
    <TableContainer>
      <Table size="small" stickyHeader>
        <TableHead>
          <StyledTableRow>
            <>
              <StyledTableCell>
                <CaptionTypo></CaptionTypo>
              </StyledTableCell>
              {header.map((item, index) => {
                return (
                  <StyledTableCell key={index}>
                    {dateFormat(item)}
                  </StyledTableCell>
                );
              })}
            </>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>
              <CaptionTypo>{title} Vol</CaptionTypo>
            </StyledTableCell>
            {body.map((item, index) => {
              return (
                <StyledTableCell key={index}>
                  {item.vo === null ? (
                    "-"
                  ) : (
                    <InputNumeric value={item.vo} displayType="text" />
                  )}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>
              <CaptionTypo>
                {title} (Val {currencySymbol})
              </CaptionTypo>
            </StyledTableCell>
            {body.map((item, index) => {
              return (
                <StyledTableCell key={index}>
                  {item.v === null ? (
                    "-"
                  ) : (
                    <InputNumeric value={item.v} displayType="text" />
                  )}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SummaryTable;

SummaryTable.propTypes = {
  title: propTypes.string,
  header: propTypes.array,
  body: propTypes.array,
};
