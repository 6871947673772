import CloseIcon from "@mui/icons-material/Close";
import { IconButton, SwipeableDrawer } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { abbrForLocPerProd } from "../../helper/commonHelper";
import useFullscreen from "../../hooks/useFullScreen";
import {
    API_ABRIVN_KEYS,
    API_ABRIVN_KEYS_TO_VALUE,
    FILTER_TYPES,
    ForecastStatus,
    statusOption
} from "../../utils/Constant";
import { Button } from "../button";
import FilterDropdown from "./FilterDropdown";
import "./style.scss";

const Filter = (props) => {
    const [drawer, setDrawer] = useState({ right: false });
    const [selectedData, setSelectedData] = useState({});
    const [clearAll, setClearAll] = useState({ isClearAll: false });
    const [filterBar, setFilterbar] = useState([]);
    const [selectedFilterData, setSelectedFilterData] = useState([]);
    const [applyAction, setApplyAction] = useState({ isApplied: false });
    const [selectedStatusOptions, setSelectedStatusOptions] = useState([]);
    const isFullscreen = useFullscreen();
    const toggleDrawer = (bool) => () => {
        setDrawer({ right: bool });
    };

    const handleClear = () => {
        setSelectedData({});
        resetFilterbar();
        setApplyAction({ isApplied: false });
        setSelectedStatusOptions([]);
        props.clearCallback();
    }

    const onApplyClick = () => {
        toggleDrawer(false)();
        setApplyAction({ isApplied: true });
    };

    const onCancelClick = () => {
        setDrawer({ right: false });
    };

    const handleStatusChange = (data) => {
        setSelectedStatusOptions(data);
    };

    const overrideStrings = useMemo(() => {
        return {
            allItemsAreSelected: ("All"),
            selectAll: ("Select All"),
        };
    }, []);

    useEffect(() => {
        if (applyAction.isApplied) {
            showFilterData(selectedStatusOptions);
            // To get the lowest level values in the dropdown
            let lowerLevelData = getLowerLevelData();
            // create status filter data
            let statusLn = selectedStatusOptions.length;
            if (statusLn > 0 && statusLn !== 3) {
                lowerLevelData["status"] = selectedStatusOptions;
            }

            let payloadData = {
                sku_id: lowerLevelData["products"]?.length > 0 ? lowerLevelData["products"]?.map((el) => el.v ?? el.value) : [],
                channel_id: lowerLevelData["channels"]?.length > 0 ? lowerLevelData["channels"]?.map((el) => el.v ?? el.value) : [],
                node_id: lowerLevelData["locations"]?.length > 0 ? lowerLevelData["locations"]?.map((el) => el.v ?? el.value) : [],
                status: lowerLevelData["status"]?.length > 0 ? lowerLevelData["status"]?.map((el) => el.value) : []
            }

            props.applyCallback(payloadData);
            selectedData["status"] = [...selectedStatusOptions];
            //API for filter
        }
    }, [applyAction]);

    useEffect(() => {
        if (applyAction.isApplied) {
            let statusFilter = selectedData["status"]?.filter((item) => ForecastStatus[item?.value]);
            if (statusFilter?.length > 0) {
                setSelectedStatusOptions(statusFilter);
            } else {
                setSelectedStatusOptions([]);
            }
        }
        else {
            setSelectedStatusOptions([]);
        }
    }, [drawer]);

    const changeAbbrToFullValue = (key) => {
        let label = key && key?.replace(/[\d]/g, "");
        const level = key && key?.replace(/[^\d]/g, "");
        return levelValue(label) + level;
    };

    const levelValue = (label) => {
        let labelValue = "";
        switch (label) {
            case "p":
                labelValue = "productHierarchyLevel";
                break;
            case "l":
                labelValue = "locationHierarchyLevel";
                break;
            case "c":
                labelValue = "channelLevel";
                break;
            case "s":
                labelValue = "salesmanHierarchyLevel";
                break;
            default:
                labelValue = label;
                break;
        }
        return labelValue;
    };

    const getLowerLevelData = () => {
        let dynamicResponse = props.dynamicResponse;
        let selectedObj = selectedData;
        let lowerLevelData = {};
        for (let i = 0; i < Object.keys(dynamicResponse).length; i++) {
            let mainObj = dynamicResponse[Object.keys(dynamicResponse)[i]];
            let selectedArray = [];
            for (let j = 0; j < Object.keys(mainObj).length; j++) {
                let levelElement = mainObj[Object.keys(mainObj)[j]];
                if (
                    j == 0 &&
                    !(
                        selectedObj[
                        API_ABRIVN_KEYS_TO_VALUE[Object.keys(dynamicResponse)[i]]
                        ] &&
                        selectedObj[
                        API_ABRIVN_KEYS_TO_VALUE[Object.keys(dynamicResponse)[i]]
                        ][changeAbbrToFullValue(Object.keys(mainObj)[j])]
                    )
                ) {
                    continue;
                }
                if (
                    selectedObj[
                    API_ABRIVN_KEYS_TO_VALUE[Object.keys(dynamicResponse)[i]]
                    ] &&
                    selectedObj[
                    API_ABRIVN_KEYS_TO_VALUE[Object.keys(dynamicResponse)[i]]
                    ][changeAbbrToFullValue(Object.keys(mainObj)[j])]
                ) {
                    selectedArray =
                        selectedObj[
                        API_ABRIVN_KEYS_TO_VALUE[Object.keys(dynamicResponse)[i]]
                        ][changeAbbrToFullValue(Object.keys(mainObj)[j])];
                } else {
                    let dynamicArray =
                        dynamicResponse[Object.keys(dynamicResponse)[i]][
                        Object.keys(mainObj)[j]
                        ];
                    let updatedArray = [];
                    // Selected array values
                    let selectedValues = selectedArray.map((data, i) => {
                        return data?.value ? data?.value : data[API_ABRIVN_KEYS["value"]];
                    });
                    for (let k = 0; k < dynamicArray.length; k++) {
                        let element = dynamicArray[k];
                        if (
                            selectedValues.includes(
                                dynamicArray[k][API_ABRIVN_KEYS["parent"]]
                            )
                        ) {
                            updatedArray.push(element);
                        }
                    }
                    selectedArray = updatedArray;
                }
                if (j == Object.keys(mainObj).length - 1) {
                    lowerLevelData[
                        API_ABRIVN_KEYS_TO_VALUE[Object.keys(dynamicResponse)[i]]
                    ] = selectedArray;
                }
            }
        }
        return lowerLevelData;
    };

    const handleClearAll = () => {
        setClearAll({ isClearAll: true });
        setApplyAction({ isApplied: false });
        setSelectedStatusOptions([]);
        setSelectedData({})
    };

    const changeKeys = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            acc[abbrForLocPerProd(key)] =
                obj[key] &&
                obj[key]?.map((item) => {
                    const items = {};
                    for (let k in item) {
                        items[API_ABRIVN_KEYS_TO_VALUE[k]] = item[k];
                    }
                    return items;
                });
            return acc;
        }, {});
    };


    const resetFilterbar = () => {
        setFilterbar([]);
    };

    const showFilterData = (selectedStatusOptions) => {
        resetFilterbar();
        let filterObj = selectedData;
        let filterResponse = [];
        if (filterObj) {
            for (const prop in filterObj) {
                let mainObj = filterObj[prop];
                let reverseArray = [...Object.keys(mainObj)].reverse();
                reverseArray.forEach((data) => {
                    filterResponse = filterResponse.concat(mainObj[data]);
                });
            }
        }

        // set status filter bar data
        let statusLn = selectedStatusOptions.length;
        if (statusLn > 0 && statusLn !== 3) {
            const mergedArray = filterResponse.concat(selectedStatusOptions);
            const uniqueArray = Array.from(new Set(mergedArray.map(JSON.stringify))).map(JSON.parse);
            setFilterbar(uniqueArray);
        } else {
            setFilterbar(filterResponse);
        }
    };

    const clearFilterValue = (hierarchy) => {
        // remove status filter bar data
        let filterStatusData = selectedStatusOptions.filter(
            (item) => item?.value !== hierarchy.value
        );
        setSelectedStatusOptions(filterStatusData);
        // Remove the value from the selectedData
        let removedItemSelectedData = removeValueFromObj(hierarchy.value);
        let parentTypes = [];
        // Initial parent type
        parentTypes.push(hierarchy.value);
        while (parentTypes.length != 0) {
            parentTypes = removeParentTypesFromObj(
                parentTypes,
                removedItemSelectedData,
                filterStatusData
            );
        }
    };

    const removeValueFromObj = (hierarchyValue) => {
        let selectedObj = selectedData;
        let isElementFound = false;
        for (let i = 0; i < Object.keys(selectedObj).length; i++) {
            let mainObj = selectedObj[Object.keys(selectedObj)[i]];
            for (let j = 0; j < Object.keys(mainObj).length; j++) {
                let arrayList =
                    selectedObj[Object.keys(selectedObj)[i]][Object.keys(mainObj)[j]];
                let elementIndex = null;
                for (let k = 0; k < arrayList.length; k++) {
                    let element = arrayList[k];
                    if (element.value == hierarchyValue) {
                        elementIndex = k;
                        break;
                    }
                }
                if (elementIndex != null) {
                    arrayList.splice(elementIndex, 1);
                    if (arrayList.length > 0) {
                        selectedObj[Object.keys(selectedObj)[i]][Object.keys(mainObj)[j]] =
                            arrayList;
                    } else {
                        delete selectedObj[Object.keys(selectedObj)[i]][
                            Object.keys(mainObj)[j]
                        ];
                    }
                    isElementFound = true;
                    break;
                }
            }
            if (isElementFound) {
                break;
            }
        }
        return selectedObj;
    };

    const removeParentTypesFromObj = (parentsType, removedItemSelectedData, filterStatusData) => {
        let selectedObj = removedItemSelectedData;
        let parentTypes = [];
        for (let i = 0; i < Object.keys(selectedObj).length; i++) {
            let mainObj = selectedObj[Object.keys(selectedObj)[i]];
            for (let j = 0; j < Object.keys(mainObj).length; j++) {
                let arrayList =
                    selectedObj[Object.keys(selectedObj)[i]][Object.keys(mainObj)[j]];
                let elementIndex = null;
                for (let k = 0; k < arrayList.length; k++) {
                    let element = arrayList[k];
                    if (element.parent && parentsType.includes(element.parent)) {
                        elementIndex = k;
                        parentTypes.push(element.value);
                    }
                }
                if (elementIndex != null) {
                    arrayList.splice(elementIndex, 1);
                    if (arrayList.length > 0) {
                        selectedObj[Object.keys(selectedObj)[i]][Object.keys(mainObj)[j]] =
                            arrayList;
                    } else {
                        delete selectedObj[Object.keys(selectedObj)[i]][
                            Object.keys(mainObj)[j]
                        ];
                    }
                    break;
                }
            }
        }
        selectedObj["status"] = filterStatusData;
        setSelectedData(selectedObj);
        selectedObj
            ? setSelectedFilterData({ ...selectedObj })
            : setSelectedFilterData({});
        // To get the lowest level values in the dropdown

        showFilterData(filterStatusData);
        // To get the lowest level values in the dropdown
        let lowerLevelData = getLowerLevelData();

        // create status filter data
        let statusLn = filterStatusData.length;
        if (statusLn > 0 && statusLn !== 3) {
            lowerLevelData["status"] = filterStatusData;
        }

        let payloadData = {
            sku_id: lowerLevelData["products"]?.length > 0 ? lowerLevelData["products"]?.map((el) => el.v ?? el.value) : [],
            channel_id: lowerLevelData["channels"]?.length > 0 ? lowerLevelData["channels"]?.map((el) => el.v ?? el.value) : [],
            node_id: lowerLevelData["locations"]?.length > 0 ? lowerLevelData["locations"]?.map((el) => el.v ?? el.value) : [],
            status: lowerLevelData["status"]?.length > 0 ? lowerLevelData["status"]?.map((el) => el.value) : []
        }
        props.applyCallback(payloadData);

        return parentTypes;
    };

    return (
        <>
            <div className="filter-container">
                <div>
                    <label>Filters:</label>
                </div>
                <div className="selected-filter-container">
                    <ul>
                        {filterBar &&
                            filterBar?.map((data, i) => {
                                return (
                                    <li key={i} className="filter-li">
                                        <button
                                            className="close-button"
                                            onClick={() => clearFilterValue(data)}
                                        >
                                            <i
                                                className="fa fa-times permission-clear"
                                                aria-hidden="true"
                                            // onClick={() => removePermission(item)}
                                            ></i>
                                        </button>
                                        <span>{data.label}</span>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                <div className="button-container">
                    <Button
                        buttonType={"outline-primary"}
                        className={"cancel-button"}
                        onClick={handleClear}
                        disabled={filterBar.length == 0}
                        label={"Clear"}
                    />
                    <Button
                        buttonType={"primary"}
                        onClick={toggleDrawer(true)}
                        label={"Filter"}
                        className="start d-flex"
                        icon={""}
                        iconPosition={"start"}
                    />
                </div>
            </div>

            {/* Drawer menu containing the filter options */}
            <SwipeableDrawer
                anchor="right"
                className={"filter-drawer"}
                open={drawer["right"]}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                disablePortal={isFullscreen}
            >
                <>
                    <div className="drawer-header-button">
                        <div className="left-section">
                            <IconButton
                                onClick={onCancelClick}
                                size="small"
                                className="close-icon"
                            >
                                <CloseIcon />
                            </IconButton>
                            <label>Filters:</label>
                        </div>
                        <div className="right-section">
                            <button onClick={handleClearAll}>{"Clear All"}</button>
                        </div>
                    </div>

                    {props.dropDownTypes.map((_, i) => {
                        return (
                            <div key={i} className="grid-con grid-con-box">
                                {Object.keys(props.dynamicResponse[props.dropDownTypes[i]])
                                    .length !== 0 && (
                                        <>
                                            <p className="p-heading">
                                                {FILTER_TYPES[props.dropDownTypes[i]]}
                                            </p>
                                            <div className="grid-item">
                                                <FilterDropdown
                                                    noOfDropDown={
                                                        Object.keys(
                                                            props.dynamicResponse[props.dropDownTypes[i]]
                                                        ).length - 1
                                                    }
                                                    hierarchyLevel={abbrForLocPerProd(
                                                        Object.keys(
                                                            props.dynamicResponse[props.dropDownTypes[i]]
                                                        )[0]
                                                    )?.replace(/[^\d]/g, "")}
                                                    responseData={changeKeys(
                                                        props.dynamicResponse[props.dropDownTypes[i]]
                                                    )}
                                                    dropDownType={abbrForLocPerProd(
                                                        Object.keys(
                                                            props.dynamicResponse[props.dropDownTypes[i]]
                                                        )[0]
                                                    )?.replace(/[\d]/g, "")}
                                                    dropDownName={
                                                        API_ABRIVN_KEYS_TO_VALUE[props.dropDownTypes[i]]
                                                    }
                                                    selectedData={selectedData}
                                                    drawer={drawer}
                                                    clearAll={clearAll}
                                                    applyAction={applyAction}
                                                    parentType=""
                                                />
                                            </div>
                                        </>
                                    )}
                            </div>
                        );
                    })}

                    <div
                        key={"status"}
                        className="grid-con grid-con-box alerts-con-box"
                    >
                        <div item className="grid-item">
                            <div className="dropdown-container">
                                <label>{"Status"}</label>
                                <MultiSelect
                                    options={statusOption}
                                    labelledBy="Status"
                                    onChange={handleStatusChange}
                                    value={selectedStatusOptions}
                                    overrideStrings={overrideStrings}
                                    ClearSelectedIcon
                                />
                            </div>
                        </div>
                    </div>

                    <div className="drawer-footer-button">
                        <Button
                            buttonType={"outline-primary"}
                            onClick={onCancelClick}
                            className={"cancel-button"}
                            label={"Cancel"}
                        />
                        <Button
                            buttonType={"primary"}
                            onClick={onApplyClick}
                            label={"Apply"}
                        />
                    </div>
                </>
            </SwipeableDrawer>
        </>
    );
};

export default Filter;
