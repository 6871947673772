import moment from "moment";
import { NPIDays, planningCycleFrequency } from "../config";
import { HORIZON_FREQUENCY } from "../utils/Constant";

export const dateFormat = (date) => {
  let dat = new Date(date);
  if (planningCycleFrequency === HORIZON_FREQUENCY.MONTHLY) {
    return moment(date).format("MMM YY");
  } else if (planningCycleFrequency === HORIZON_FREQUENCY.DAILY) {
    return moment(dat).format("YYYY-MM-DD");
  } else {
    dat.setDate(dat.getDate() + 6);
    return `${moment(date).format("MM/DD")} - ${moment(dat).format("MM/DD")}`;
  }
};

export const createMinAndMaxDate = (date) => {
  let minDate = moment(date).subtract(NPIDays, "days").format("YYYY-MM-DD");
  let maxDate = moment(date).add(NPIDays, "days").format("YYYY-MM-DD");
  return {
    minDate,
    maxDate,
  };
};

export const createHorizon = (minDate, maxDate) => {
  let period = [];
  let startDate = moment(minDate);
  let endDate = moment(maxDate);
  while (startDate.isSameOrBefore(endDate)) {
    if (planningCycleFrequency === HORIZON_FREQUENCY.MONTHLY) {
      period.push(startDate.format(`YYYY-MM-01`));
      startDate = startDate.add(1, "month");
    } else if (planningCycleFrequency === HORIZON_FREQUENCY.DAILY) {
      period.push(startDate.format("YYYY-MM-DD"));
      startDate = startDate.add(1, "day");
    } else {
      period.push(startDate.format("YYYY-MM-DD"));
      startDate = startDate.add(6, "day");
    }
  }
  return period;
};

export const findMinAndMaxDate = (data) => {
  let minDate = moment(data?.[0]?.p);
  let maxDate = moment(data?.[0]?.p);
  data.forEach((item) => {
    let date = moment(item.p);
    if (date.isBefore(minDate)) {
      minDate = date;
    }
    if (date.isAfter(maxDate)) {
      maxDate = date;
    }
  });
  return {
    minDate,
    maxDate,
  };
};

export const sortOnDate = (data) => {
  const newData = [...data];
  return newData.sort((a, b) => {
    let dateA = new Date(a.p);
    let dateB = new Date(b.p);
    return dateA - dateB;
  });
};
