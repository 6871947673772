import propTypes from "prop-types";
import "./style.scss";

function Status({ status, ...otherProps }) {
  return (
    <span
      {...otherProps}
      className={`forecast-status status-${status.toLowerCase()}`}
    >
      {status}
    </span>
  );
}

export default Status;

Status.propTypes = {
  status: propTypes.string.isRequired,
};
