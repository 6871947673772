import moment from "moment";
import { marketingDays } from "../config";
import { store } from "../store";
import { setForecasting } from "../store/slices/globalConfig";
import { getMarketingSlice } from "../store/slices/marketing";
import {
  FORECAST_CATEGORIES,
  LIGHT_TYPE,
  disabledOnStatus,
} from "../utils/Constant";
import { createHorizon, findMinAndMaxDate, sortOnDate } from "./date-helper";
import { getHierarchyLevelPayload } from "./payload-helper";
import { getHierarchyLevel, hierarchyLevel } from "./user-helper";

export const formatTableHeaderPosition = (headerData) => {
  let header = Object.keys(FORECAST_CATEGORIES).reduce((acc, key) => {
    if (key in headerData) {
      acc[key] = headerData[key];
    }
    return acc;
  }, {});
  return header;
};

export const findTableObj = (header) => {
  for (let i = 0; i < header.length; i++) {
    if (typeof header[i] === "object") {
      return header[i];
    }
  }
  return {};
};

const createHeaderArr = ({
  isBaseline,
  isStatistical,
  isOperational,
  isSales,
  isUnconstrained,
  marketingPre,
  statisticalPre,
}) => {
  let headerObj = {
    marketing: marketingPre,
  };
  if (isBaseline) headerObj.baseline = statisticalPre;
  if (isStatistical) headerObj.statistical = statisticalPre;
  if (isOperational) headerObj.operational = statisticalPre;
  if (isSales) headerObj.sales = statisticalPre;
  if (isUnconstrained) headerObj.unconstrained = statisticalPre;

  const { groupLevelFilter } = store.getState().MarketingReducer;
  const { ph, lh } = getHierarchyLevelPayload(groupLevelFilter);
  let locationBase =
    lh === 0
      ? getHierarchyLevel.LOCATION[hierarchyLevel.BASE]
      : getHierarchyLevel.LOCATION[`${hierarchyLevel.LOCATION}${lh}`];
  let productBase =
    ph === 0
      ? getHierarchyLevel.PRODUCT[hierarchyLevel.BASE]
      : getHierarchyLevel.PRODUCT[`${hierarchyLevel.PRODUCT}${ph}`];

  store.dispatch(
    getMarketingSlice({
      whenSorting: {
        [productBase]: "sn",
        [locationBase]: "n",
        Channel: "cn",
        Status: "st",
      },
      isGroupFilterApply: lh > 0 || ph > 0,
    })
  );

  let headerData = [
    "",
    productBase,
    locationBase,
    "Channel",
    "Status",
    "",
    formatTableHeaderPosition(headerObj),
    "Approval",
  ];
  return headerData;
};

const findMarketingPeriodAndWhichForecastEnable = (detailArr) => {
  let isBaseline = false;
  let isStatistical = false;
  let isOperational = false;
  let isSales = false;
  let isUnconstrained = false;
  let marketingArr = [];
  let statisticalArr = [];
  for (let i = 0; i < detailArr.length; i++) {
    let dt = detailArr[i];
    if (dt.ft === FORECAST_CATEGORIES.baseline) isBaseline = true;
    if (dt.ft === FORECAST_CATEGORIES.statistical) {
      isStatistical = true;
      statisticalArr.push(dt);
    }
    if (dt.ft === FORECAST_CATEGORIES.operational) isOperational = true;
    if (dt.ft === FORECAST_CATEGORIES.sales) isSales = true;
    if (dt.ft === FORECAST_CATEGORIES.unconstrained) isUnconstrained = true;
    if (dt.ft === FORECAST_CATEGORIES.marketing) marketingArr.push(dt);
  }
  return {
    marketingArr,
    statisticalArr,
    isBaseline,
    isStatistical,
    isOperational,
    isSales,
    isUnconstrained,
  };
};

export const createHeaderData = (data) => {
  let detailArr = [];
  for (let i = 0; i < data.length; i++) {
    detailArr = [...detailArr, ...data[i].fd];
  }
  const {
    isBaseline,
    isStatistical,
    isOperational,
    isSales,
    isUnconstrained,
    marketingArr,
    statisticalArr,
  } = findMarketingPeriodAndWhichForecastEnable(detailArr);
  let { minDate, maxDate } = findMinAndMaxDate(marketingArr);

  let statisticalMinMax;
  let statisticalPre;
  if (isStatistical) {
    statisticalMinMax = findMinAndMaxDate(statisticalArr);
    statisticalPre = createHorizon(
      statisticalMinMax.minDate,
      statisticalMinMax.maxDate
    );
  } else {
    store.dispatch(setForecasting({ isForecasting: false }));
  }

  let marketingPre = createHorizon(minDate, maxDate);

  return createHeaderArr({
    isBaseline,
    isStatistical,
    isOperational,
    isSales,
    isUnconstrained,
    marketingPre,
    statisticalPre,
  });
};

export const formatTableRowForMark = (detail, period, type) => {
  let detailArr = [];
  for (let i = 0; i < period.length; i++) {
    let key = `${type}-${period[i]}`;
    if (detail[key]) {
      let obj = {
        ...detail[key],
        isAvl:
          type === FORECAST_CATEGORIES.marketing && detail[key].fdi
            ? true
            : false,
        isForecastAvl: true,
      };
      detailArr.push(obj);
    } else {
      detailArr.push({
        vo: null,
        v: null,
        p: period[i],
        isAvl: false,
        isForecastAvl: false,
      });
    }
  }
  return detailArr;
};

export const formatTableRow = (detail, headerObjForecast) => {
  let isStatistical = !!headerObjForecast?.statistical;
  let statisticalPeriod = headerObjForecast.statistical;
  let isBaseline = !!headerObjForecast?.baseline;
  let isOperational = !!headerObjForecast?.operational;
  let isSales = !!headerObjForecast?.sales;
  let isUnconstrained = !!headerObjForecast?.unconstrained;
  let baselineArr = [];
  let statisticalArr = [];
  let operationalArr = [];
  let salesArr = [];
  let unconstrainedArr = [];
  const len = isStatistical ? statisticalPeriod.length : 0;
  for (let i = 0; i < len; i++) {
    let p = statisticalPeriod[i];
    let baselineKey = `${FORECAST_CATEGORIES.baseline}-${p}`;
    let statisticalKey = `${FORECAST_CATEGORIES.statistical}-${p}`;
    let operationalKey = `${FORECAST_CATEGORIES.operational}-${p}`;
    let salesKey = `${FORECAST_CATEGORIES.sales}-${p}`;
    let unconstrainedKey = `${FORECAST_CATEGORIES.unconstrained}-${p}`;
    let ifNotAvl = {
      vo: null,
      v: null,
      p,
      isAvl: false,
      isForecastAvl: false,
    };

    if (isBaseline && detail[baselineKey]) {
      baselineArr.push({
        ...detail[baselineKey],
        isForecastAvl: true,
      });
    } else baselineArr.push(ifNotAvl);

    if (detail[statisticalKey]) {
      statisticalArr.push({
        ...detail[statisticalKey],
        isForecastAvl: true,
      });
    } else statisticalArr.push(ifNotAvl);

    if (isOperational && detail[operationalKey]) {
      operationalArr.push({
        ...detail[operationalKey],
        isForecastAvl: true,
      });
    } else operationalArr.push(ifNotAvl);

    if (isSales && detail[salesKey]) {
      salesArr.push({
        ...detail[salesKey],
        isForecastAvl: true,
      });
    } else salesArr.push(ifNotAvl);

    if (isUnconstrained && detail[unconstrainedKey]) {
      unconstrainedArr.push({
        ...detail[unconstrainedKey],
        isForecastAvl: true,
      });
    } else unconstrainedArr.push(ifNotAvl);
  }

  return {
    ...(isStatistical && { statistical: statisticalArr }),
    ...(isBaseline && { baseline: baselineArr }),
    ...(isOperational && { operational: operationalArr }),
    ...(isSales && { sales: salesArr }),
    ...(isUnconstrained && { unconstrained: unconstrainedArr }),
  };
};

export const formatTableBody = (details, headerObjForecast) => {
  let detailObj = {};
  for (let i = 0; i < details.length; i++) {
    let key = `${details[i].ft}-${details[i].p}`;
    detailObj[key] = details[i];
  }

  // we need to optime this code to avoid multiple iteration with the same data when i get real data
  let forecastObj = formatTableRow(detailObj, headerObjForecast);
  forecastObj.marketing = formatTableRowForMark(
    detailObj,
    headerObjForecast.marketing,
    FORECAST_CATEGORIES.marketing
  );

  let formatPosition = formatTableHeaderPosition(forecastObj);

  return formatPosition;
};

export const findAndUpdate = ({ data, item, uniqueKey, fhi, st, atl }) => {
  return data.map((dta) => {
    let mainKey = `${dta.si}-${dta.ni}-${dta.ci}`;
    if (mainKey === uniqueKey) {
      let newDta = dta.fd.map((dt) => {
        let key = `${dt.ft}-${dt.p}`;
        let periodKey = `${item.ft}-${item.p}`;
        if (key === periodKey) {
          return {
            ...item,
          };
        }
        return dt;
      });
      return {
        ...dta,
        fd: newDta,
        fhi,
        st,
        atl,
      };
    } else {
      return dta;
    }
  });
};

export const reviewAndRejectFindAndUpdate = ({ data, bodyData }) => {
  let hashMap = new Map();
  data.forEach((item) => {
    hashMap.set(item.fhi, item);
  });

  let newBodyData = bodyData.map((dta) => {
    let find = hashMap.get(dta.fhi);
    if (find) {
      return {
        ...dta,
        ...find,
      };
    }
    return dta;
  });
  return newBodyData;
};

export const getStickyCol = (index) => {
  if (index > 5) return "";
  return ["stickyCol", `stickyCol-${index}`].join(" ");
};

export const createDemandSummaryHeader = (data) => {
  let { minDate, maxDate } = findMinAndMaxDate(data);
  return createHorizon(minDate, maxDate);
};

export const createDemandSummary = (data) => {
  return {
    ...(data?.b?.length > 0 && {
      baseline: {
        header: createDemandSummaryHeader(data.b),
        body: data.b,
      },
    }),
    ...(data?.s?.length > 0 && {
      statistical: {
        header: createDemandSummaryHeader(data.s),
        body: data.s,
      },
    }),
    ...(data?.m?.length > 0 && {
      marketing: {
        header: createDemandSummaryHeader(data.m),
        body: sortOnDate(data.m),
      },
    }),
    ...(data?.o?.length > 0 && {
      operational: {
        header: createDemandSummaryHeader(data.o),
        body: data.o,
      },
    }),
    ...(data?.sa?.length > 0 && {
      sales: {
        header: createDemandSummaryHeader(data.sa),
        body: data.sa,
      },
    }),
    ...(data?.u?.length > 0 && {
      unconstrained: {
        header: createDemandSummaryHeader(data.u),
        body: data.u,
      },
    }),
  };
};

export const isDisabledOnLoading = ({ lightType }) => {
  return lightType === LIGHT_TYPE.LOADING;
};

export const isDisabledFunc = ({ period, lstd }) => {
  if (!lstd) return true;
  let currDate = moment();
  let per = moment(period);
  let lifeCycleStartDate = moment(lstd);
  let marketingEditableDate = moment(lstd).add(marketingDays, "days");
  if (
    per.isSameOrAfter(lifeCycleStartDate) &&
    per.isSameOrAfter(currDate) &&
    per.isSameOrBefore(marketingEditableDate)
  ) {
    return false;
  }
  return true;
};

export const selectDisabledFunc = ({ fhi, isGroupFilterApply, status }) => {
  if (isGroupFilterApply || !fhi || disabledOnStatus[status]) return true;
  return false;
};
