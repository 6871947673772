import { combineReducers } from "redux";
import FilterReducer from "../slices/filter";
import FilterbarReducer from "../slices/filterBar";
import GlobalConfigReducer from "../slices/globalConfig";
import ProgressReducer from "../slices/loading";
import MarketingReducer from "../slices/marketing";

export const rootReducer = combineReducers({
  ProgressReducer,
  MarketingReducer,
  GlobalConfigReducer,
  FilterbarReducer,
  FilterReducer,
});
