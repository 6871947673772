import { Box } from "@mui/material";
import propType from "prop-types";
import { NumericFormat } from "react-number-format";
import { localeConfiguration } from "../../config";
import { isInvalidKeyDown } from "../../helper/input-helper";
import { MAX_INPUT_LIMIT } from "../../utils/Constant";
import "./style.scss";

const light = {
  success: "success-light",
  error: "error-light",
  loading: "loading-light",
};

function InputNumeric({ isLight, lightType, displayType, disabled, ...props }) {
  return (
    <Box
      sx={{
        position: "relative",
      }}
      className={[isLight && light[lightType]].join(" ")}
    >
      <Box
        sx={{
          borderRadius: 1,
          color: "input.color",
          border: (theme) => `1px solid ${theme.palette.input.border}`,
          fontWeight: 600,
          maxWidth: 60,
          pl: 0.5,
          py: 0.3,
          fontSize: 12,
          ...(disabled && {
            background: (theme) => theme.palette.myCommon.disable,
          }),
        }}
        component={NumericFormat}
        min="0"
        type="number"
        allowNegative={false}
        renderText={(value) => value}
        displayType={displayType}
        thousandsGroupStyle={
          displayType !== "input" && localeConfiguration["thousandsGroupStyle"]
        }
        thousandSeparator={
          displayType !== "input" && localeConfiguration["thousandSeparator"]
        }
        disabled={disabled}
        onKeyDown={(e) => {
          if (isInvalidKeyDown(e)) e.preventDefault();
        }}
        isAllowed={(values) => {
          if (!values.value) return true;
          const { floatValue } = values;
          return floatValue < MAX_INPUT_LIMIT;
        }}
        {...props}
      />
    </Box>
  );
}

export default InputNumeric;
InputNumeric.propTypes = {
  isLight: propType.bool,
  lightType: propType.string,
  displayType: propType.string,
  disabled: propType.bool,
};
