import { Dialog } from "@mui/material";
import propTypes from "prop-types";
import useFullscreen from "../../hooks/useFullScreen";
import Images from "../../utils/Images";

function Loader({ isShow }) {
  const isFullscreen = useFullscreen();
  return (
    <Dialog
      disableEnforceFocus
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1,
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
          boxShadow: "unset",
        },
      }}
      open={isShow}
      disablePortal={isFullscreen}
    >
      <img src={Images.loader} alt="loader" />
    </Dialog>
  );
}

export default Loader;

Loader.propTypes = {
  isShow: propTypes.bool.isRequired,
};
