import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import useFullscreen from "../../hooks/useFullScreen";
import "./dialog.scss";

const CustomDialog = ({ handleClose, children, className, open }) => {
  const isFullscreen = useFullscreen();
  return (
    <>
      <Dialog
        className={className ?? ""}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        disablePortal={isFullscreen}
      >
        <DialogContent dividers className="dialog-content">
          <Box className="dialog-box">
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className="icon-button"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomDialog;

CustomDialog.propTypes = {
  handleOpen: PropTypes.func,
  children: PropTypes.node,
  handleClose: PropTypes.func,
};
