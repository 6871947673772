import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    forecastFilterData: {},
    forecastSelectedFilterData: {},
    forecastSelectedLowerLevelData: {},
    forecastChartData: [],
};

export const FiltersSlice = createSlice({
    name: "filter",
    initialState: initialState,
    reducers: {
        setForecastFilterSlice: (state, action) => {
            state.forecastFilterData = action.payload;
        },
        setForecastSelectedFilterDataSlice: (state, action) => {
            state.forecastSelectedFilterData = action.payload;
        },
        setForecastSelectedLowerLevelDataSlice: (state, action) => {
            state.forecastSelectedLowerLevelData = action.payload;
        },
        setForecastChartDataSlice: (state, action) => {
            state.forecastChartData = action.payload;
        },
        resetForecastSelectSlice: () => {
            let initialStateOfValues = { ...initialState };
            initialStateOfValues.forecastFilterData = {};
            initialStateOfValues.forecastSelectedFilterData = {}
            initialStateOfValues.forecastSelectedLowerLevelData = {};
            initialStateOfValues.forecastChartData = [];
            return initialStateOfValues;
        },
    }
});

export const {
    setForecastFilterSlice,
    setForecastSelectedFilterDataSlice,
    setForecastSelectedLowerLevelDataSlice,
    setForecastChartDataSlice,
    resetForecastSelectSlice,
} = FiltersSlice.actions;

export default FiltersSlice.reducer;
