import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import propTypes from "prop-types";
import Logs from "./logs";

const CusPagination = ({
  page,
  rowPerPage,
  total,
  handleChangePage,
  ...otherProps
}) => {
  return (
    <Stack
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      px={1}
      sx={{ backgroundColor: "secondary.light" }}
    >
      <Pagination
        count={Math.ceil(total / rowPerPage)}
        page={page}
        onChange={(_, page) => {
          handleChangePage(page);
        }}
        sx={{
          margin: "1rem 0",
          "& .MuiPaginationItem-root": {
            fontSize: "1.25rem",
            "& .MuiSvgIcon-root": {
              fontSize: "2.2rem",
              color: "primary.main",
            },
          },
        }}
        color="primary"
        shape="rounded"
        showFirstButton
        showLastButton
        {...otherProps}
      />
      <Logs
        start={page * rowPerPage - rowPerPage + 1}
        end={page * rowPerPage > total ? total : page * rowPerPage}
        total={total}
        title="items"
      />
    </Stack>
  );
};

export default CusPagination;

CusPagination.propTypes = {
  page: propTypes.number,
  rowPerPage: propTypes.number,
  total: propTypes.number,
  handleChangePage: propTypes.func,
};
