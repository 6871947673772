import { Stack } from "@mui/material";
import RenderSortIcons from "./render-sort-icons";
import propTypes from "prop-types";

function HeaderContentSort({
  title,
  handleSort,
  column,
  sortKey,
  sortDir,
  isSorting,
}) {
  return (
    <Stack
      component="span"
      onClick={handleSort}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
      sx={{
        cursor: "pointer",
        "& i": {
          px: 1,
        },
      }}
    >
      {title}
      {isSorting && (
        <RenderSortIcons column={column} sortKey={sortKey} sortDir={sortDir} />
      )}
    </Stack>
  );
}

export default HeaderContentSort;

HeaderContentSort.propTypes = {
  title: propTypes.string,
  handleSort: propTypes.func,
  column: propTypes.string,
  sortKey: propTypes.string,
  sortDir: propTypes.bool,
  isSorting: propTypes.bool,
};
