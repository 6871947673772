import React, { memo } from "react";
import { Link } from "react-router-dom";
const Breadcrumb = React.memo(({ list, active }) => {
    return (
        <>
            {list.length > 0 ? (
                <div className="section-breadcrumbs">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {list &&
                                list.map((item, idx) => (
                                    <li
                                        key={idx}
                                        className={`breadcrumb-item ${list.length === idx + 1 ? "active" : ""
                                            }`}
                                        aria-current="page"
                                    >
                                        {
                                            typeof item == "string" ? (
                                                <span>{(item)}</span>
                                            ) : (
                                                <Link to={item?.path}>
                                                    <span className="section-breadcrumbs-a">{(item?.name)}</span>
                                                </Link>
                                            )
                                        }
                                    </li>
                                ))}
                        </ol>
                    </nav>
                </div>
            ) : (
                ""
            )}
        </>
    );
});

export default memo(Breadcrumb);
