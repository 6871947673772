import Divider from '@mui/material/Divider';
import propTypes from "prop-types";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { toastMsg } from "../../helper/toast-helper";
import { uploadCsv } from "../../services/common";
import { loadingProgress } from "../../store/slices/loading";
import { LIGHT_TYPE } from "../../utils/Constant";
import ERROR_MSG from "../../utils/ErrorMsg";
import STATUS_CODE from "../../utils/StatusCode";
import SUCCESS_MSG from "../../utils/SuccessMsg";
import { Button } from "../button";


function CsvUpload({ handleResponse, url, type, filterObj }) {
  const [uploadedFile, setUploadedFile] = useState([]);
  const dispatch = useDispatch();

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let fileArr = [];
      fileArr.push(file);
      setUploadedFile(fileArr);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const files = uploadedFile?.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const onUploadClick = async () => {
    try {
      if (uploadedFile.length > 0) {
        uploadedFile.forEach(async (file) => {
          try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("filter", JSON.stringify(filterObj));
            dispatch(loadingProgress({ isProgress: true }));
            const { status, data } = await uploadCsv({ type, url, formData });
            if (status === STATUS_CODE.OK) {
              let res = data?.responseData?.data;
              handleResponse(res.de);
              dispatch(loadingProgress({ isProgress: false }));
              toastMsg(LIGHT_TYPE.SUCCESS, SUCCESS_MSG.CSV_UP);
            } else {
              throw new Error(ERROR_MSG.SWR);
            }
          } catch (e) {
            setUploadedFile([]);
            handleResponse();
            dispatch(loadingProgress({ isProgress: false }));
            toastMsg(LIGHT_TYPE.ERROR, e?.message || ERROR_MSG.SWR);
          }
        });
      }
    } catch (e) {
      setUploadedFile([]);
      handleResponse();
      dispatch(loadingProgress({ isProgress: false }));
      toastMsg(LIGHT_TYPE.ERROR, e?.message || ERROR_MSG.SWR);
    }
  }

  return (
    <div>
      <section className="upload-container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} accept={".csv"} type="file" name="file" />
          <h4 className="title-message">Select an Excel file to upload</h4>
          <p className="sub-message">or drag or drop it here</p>
        </div>

        {uploadedFile && (
          <aside>{uploadedFile[0] ? <ul>{files}</ul> : ""}</aside>
        )}
      </section>
      <Divider />
      <div className={"upload-button"}>
        <Button
          disabled={uploadedFile.length === 0}
          buttonType={"primary"}
          label={"Upload"}
          onClick={onUploadClick}
        />
      </div>
    </div>
  );
}

export default CsvUpload;

CsvUpload.propTypes = {
  handleResponse: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  url: propTypes.string.isRequired,
  filterObj: propTypes.object.isRequired,
};
