import instanceAxios from "../config/axios";

export const uploadCsv = async ({ type, url, formData }) => {
  return await instanceAxios[type](url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const downloadCsv = async ({url, type}) => {
  return await instanceAxios[type](url, { responseType: "blob" });
}