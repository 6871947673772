import { Box } from "@mui/material";
import { TitleTypo } from "../reuse-comp-fn";
import propTypes from "prop-types";

function NoRecord({ children, ...otherProps }) {
  return (
    <Box sx={{ textAlign: "center" }} {...otherProps}>
      <TitleTypo>{children}</TitleTypo>
    </Box>
  );
}

export default NoRecord;

NoRecord.propTypes = {
  children: propTypes.node,
};
