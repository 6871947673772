import Select from "react-select";
import propTypes from "prop-types";
import { customSelectTheme } from "../../utils/Constant";
import "./style.scss";


const ReactSelect = ({handleSelectChange, ...otherProps }) => {
  return (
    <Select
      className="custom-select-container"
      onChange={handleSelectChange}
      theme={customSelectTheme}
      classNamePrefix="custom-select"
      isSearchable={false}
      isOptionDisabled={(option) => option.disabled}
      {...otherProps}
    />
  );
};

export default ReactSelect;

ReactSelect.propTypes = {
  handleSelectChange: propTypes.func,
};
