import instanceAxios from "../config/axios";
import { getHierarchyLevelPayload } from "../helper/payload-helper";
import { QUERY_ENUM, SORT_ENUM } from "../utils/Constant";
import END_POINT from "../utils/EndPoint";
import LOCAL_STORAGE from "../utils/LocalStorage";

const {
  TENANT_ID,
  BU_ID,
  SEARCH_STRING,
  SORT_KEY,
  SORT_VALUE,
  CURRENT_PAGE_INDEX,
  CURRENT_PAGE_SIZE,
  PRODUCT_HIERARCHY_LEVEL,
  LOCATION_HIERARCHY_LEVEL,
} = QUERY_ENUM;
const { TENANT_IDS, BU_IDS } = LOCAL_STORAGE;

export const getCsvDownloadMarketingURL = `${END_POINT.GET_CSV_MARKETING}?${TENANT_ID}=${TENANT_IDS}&${BU_ID}=${BU_IDS}`;

export const getDemandSummary = async ({ filterObj }) => {
  const url = `${END_POINT.DEMAND_SUMMARY}?&${TENANT_ID}=${TENANT_IDS}&${BU_ID}=${BU_IDS}`;
  return await instanceAxios.post(url, filterObj);
};

export const csvUploadMarketingURL = ({
  pageIndex,
  pageSize,
  searchKey,
  sortKey,
  sortValue,
  groupLevelFilter,
}) => {
  const { ph, lh } = getHierarchyLevelPayload(groupLevelFilter);
  const url = `${
    END_POINT.UPLOAD_CSV_MARKETING
  }?${TENANT_ID}=${TENANT_IDS}&${BU_ID}=${BU_IDS}&${CURRENT_PAGE_INDEX}=${pageIndex}&${CURRENT_PAGE_SIZE}=${pageSize}&${SEARCH_STRING}=${searchKey}&${SORT_KEY}=${sortKey}&${SORT_VALUE}=${
    sortValue ? SORT_ENUM.ASC : SORT_ENUM.DESC
  }&${PRODUCT_HIERARCHY_LEVEL}=${ph}&${LOCATION_HIERARCHY_LEVEL}=${lh}`;
  return url;
};

export const getMarketing = async ({
  pageIndex,
  pageSize,
  searchKey,
  sortKey,
  sortValue,
  filterObj,
  groupLevelFilter,
}) => {
  const { ph, lh } = getHierarchyLevelPayload(groupLevelFilter);
  return await instanceAxios.post(
    `${
      END_POINT.GET_MARKETING
    }?&${TENANT_ID}=${TENANT_IDS}&${BU_ID}=${BU_IDS}&${CURRENT_PAGE_INDEX}=${pageIndex}&${CURRENT_PAGE_SIZE}=${pageSize}&${SEARCH_STRING}=${searchKey}&${SORT_KEY}=${sortKey}&${SORT_VALUE}=${
      sortValue ? SORT_ENUM.ASC : SORT_ENUM.DESC
    }&${PRODUCT_HIERARCHY_LEVEL}=${ph}&${LOCATION_HIERARCHY_LEVEL}=${lh}`,
    filterObj
  );
};

export const saveMarketing = async ({ data, isAvl }) => {
  let url = `${END_POINT.SAVE_MARKETING}?${TENANT_ID}=${TENANT_IDS}&${BU_ID}=${BU_IDS}`;
  return await instanceAxios[isAvl ? "put" : "post"](url, data);
};

export const filterMarketing = async () => {
  let url = `${END_POINT.FILTER}?${TENANT_ID}=${TENANT_IDS}&${BU_ID}=${BU_IDS}`;
  return await instanceAxios.get(url);
};

export const rejectMarketing = async (payload) => {
  let url = `${END_POINT.REJECT}?${TENANT_ID}=${TENANT_IDS}&${BU_ID}=${BU_IDS}`;
  return await instanceAxios.put(url, payload);
};

export const reviewMarketing = async (payload) => {
  let url = `${END_POINT.REVIEW}?${TENANT_ID}=${TENANT_IDS}&${BU_ID}=${BU_IDS}`;
  return await instanceAxios.put(url, payload);
};

export const getMarketingSummary = async (payload) => {
  let url = `${END_POINT.MARKETING_SUMMARY}?${TENANT_ID}=${TENANT_IDS}&${BU_ID}=${BU_IDS}`;
  return await instanceAxios.post(url, payload);
}
