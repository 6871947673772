import axios from "axios";
import Env from "../utils/Env";
import LOCAL_STORAGE from "../utils/LocalStorage";
import { QUERY_ENUM } from "../utils/Constant";

export const  axiosConfig = {
   headers: {
    "Content-Type": "application/json",
    Authorization: `${QUERY_ENUM.BEARER} ${LOCAL_STORAGE.TOKEN}`,
  },
}

const instanceAxios = axios.create({
  baseURL: Env.BASE_URL + Env.APP,
  // timeout: 3000,
  headers: axiosConfig.headers,
});

// Add a request interceptor
instanceAxios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instanceAxios;
