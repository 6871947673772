/* eslint-disable react/prop-types */
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const CaptionTypo = ({children, ...otherProps }) => {
  return (
    <Typography
      sx={{
        color: "primary.main",
        fontWeight: "bold",
        fontSize: 11,
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export const BorderBox = ({ children, ...otherProps }) => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: (theme) => theme.palette.input.sideBorder,
        borderRadius: 1,
        p:1,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
}

export const TitleTypo = ({children, ...otherProps }) => {
  return (
    <Typography
      sx={{
        color: "secondary.main",
        fontWeight: "bold",
        fontSize: 13,
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};
