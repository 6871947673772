import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Button } from "../button";
import useFullscreen from "../../hooks/useFullScreen";

const CustomizeDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 8,
    border: `1px solid ${grey[50]}`,
    boxShadow: theme.shadows[5],
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
}));

const CusDialog = ({
  title,
  children,
  onClose,
  onOpen,
  isDialogActionShow,
  isCloseIcon,
  handleOkClick,
  ...otherProps
}) => {
  const isFullscreen = useFullscreen();
  return (
    <CustomizeDialog
      maxWidth="md"
      open={onOpen}
      onClose={onClose}
      disablePortal={isFullscreen}
      {...otherProps}
    >
      {title && (
        <DialogTitle sx={{ p: 1, display: "flex" }}>
          <Box sx={{ gap: 1, display: "flex" }}>
            <Typography
              variant="h6"
              sx={{ fontSize: 15, fontWeight: "bold" }}
              color="primary"
            >
              {title}
            </Typography>
            {isCloseIcon && (
              <IconButton
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 2,
                  top: -2,
                  color: "primary.main",
                }}
                onClick={onClose}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {isDialogActionShow && (
        <DialogActions sx={{ gap: 1 }}>
          <Button buttonType="outline-primary" label="No" onClick={onClose}/>
          <Button buttonType="primary" label="Yes" onClick={handleOkClick}/>
        </DialogActions>
      )}
    </CustomizeDialog>
  );
};

export default CusDialog;

CusDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  className: PropTypes.string,
  isDialogActionShow: PropTypes.bool,
};
